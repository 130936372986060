import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import { formatDeadlineDate } from '../utils/dateUtils';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Colleges.css';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { user } = useAuth();
  const userType = localStorage.getItem('userType');
  const navigate = useNavigate();

  // State for To-Dos (stored locally for now)
  const [todos, setTodos] = useState(() => JSON.parse(localStorage.getItem('todos')) || []);
  const [newTodo, setNewTodo] = useState('');

  // State for Calendar
  const [date, setDate] = useState(new Date());

  const [progress, setProgress] = useState(() => {
    const savedProgress = localStorage.getItem('adminMaterialsProgress');
    return savedProgress ? JSON.parse(savedProgress) : {
      schoolPhilosophy: 0,
      transcript: 0,
      courseDescriptions: 0,
      gradingRubric: 0,
      guidanceCounselorLetter: 0
    };
  });

  const [schoolList, setSchoolList] = useState([]);

  const [userCourses, setUserCourses] = useState(() => {
    const savedCourses = localStorage.getItem('userCourses');
    return savedCourses ? JSON.parse(savedCourses) : [];
  });

  const [isCompliant, setIsCompliant] = useState(false);
  const [systemTodos, setSystemTodos] = useState([
    { text: "Complete course planning", dueDate: "2024-05-01" },
    { text: "Update extracurricular activities", dueDate: "2024-05-15" },
    { text: "Schedule college counseling session", dueDate: "2024-06-01" }
  ]);

  const [complianceReason, setComplianceReason] = useState("Need to file Private School Affidavit (CA)");

  useEffect(() => {
    const savedProgress = localStorage.getItem('adminMaterialsProgress');
    if (savedProgress) {
      setProgress(JSON.parse(savedProgress));
    }
  }, []);

  useEffect(() => {
    const loadSchoolList = () => {
      const savedSchools = localStorage.getItem('mySchoolList');
      if (savedSchools) {
        try {
          const schools = JSON.parse(savedSchools);
          setSchoolList(schools);
        } catch (e) {
          console.error('Error parsing saved schools:', e);
        }
      }
    };

    loadSchoolList();
    
    // Add event listener for storage changes
    window.addEventListener('storage', loadSchoolList);
    return () => window.removeEventListener('storage', loadSchoolList);
  }, []);

  // Save todos to localStorage whenever they are updated
  useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos));
  }, [todos]);

  // Handle adding a new to-do
  const handleAddTodo = () => {
    if (newTodo.trim() === '') return;
    setTodos([...todos, newTodo]);
    setNewTodo('');
  };

  // Handle deleting a to-do
  const handleDeleteTodo = (index) => {
    const updatedTodos = todos.filter((_, i) => i !== index);
    setTodos(updatedTodos);
  };

  // Update progress from localStorage when it changes
  useEffect(() => {
    const handleStorageChange = () => {
      const savedProgress = localStorage.getItem('adminMaterialsProgress');
      if (savedProgress) {
        setProgress(JSON.parse(savedProgress));
      }
    };

    // Initial load
    handleStorageChange();

    // Listen for changes
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const formatDeadline = (dateStr) => {
    if (!dateStr) return null;
    return formatDeadlineDate(dateStr); // Using the existing formatDeadlineDate function
  };

  // Add useEffect to listen for changes in localStorage
  useEffect(() => {
    const loadCourses = () => {
      const savedCourses = localStorage.getItem('userCourses');
      if (savedCourses) {
        setUserCourses(JSON.parse(savedCourses));
      }
    };

    loadCourses();
    window.addEventListener('storage', loadCourses);
    return () => window.removeEventListener('storage', loadCourses);
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        <div style={styles.splitLayout}>
          {/* Left Column (1/3) */}
          <div style={styles.leftColumn}>
            <div style={styles.mainContainer}>
              <h2 style={styles.welcomeHeader}>
                <span style={styles.graduationCap}>🎓</span>✨ Welcome High School Students! ✨<span style={styles.graduationCap}>🎓</span>
              </h2>
              <p style={styles.introText}>
                Welcome to YourEDU, a platform designed to help you navigate your high school journey and prepare for college.
              </p>
              <div style={styles.resourceContainer}>
                <h2 style={styles.resourceHeader}>High School Program Overview</h2>
                <p style={styles.resourceText}>
                  Our program offers guidance on course planning, college preparation, extracurricular activities, and more.
                </p>
              </div>
              <div style={styles.tipsContainer}>
                <h2 style={styles.tipsHeader}>Key Resources</h2>
                <ul style={styles.tipsList}>
                  {[
                    { emoji: '📚', title: 'My Courses', desc: 'Track and manage your current academic courses and progress' },
                    { emoji: '📘', title: 'Course Planning', desc: 'Design your personalized high school curriculum and academic roadmap' },
                    { emoji: '🔍', title: 'Course Search', desc: 'Explore available courses and find the perfect fit for your interests' },
                    { emoji: '📝', title: 'Registration', desc: 'Complete your course registration and manage enrollment status' },
                    { emoji: '🎭', title: 'Extracurriculars', desc: 'Discover and track activities that enhance your college applications' },
                    { emoji: '💼', title: 'Career Exploration', desc: 'Research different career paths and find your passion' },
                    { emoji: '🎓', title: 'College Prep', desc: 'Get guidance on college applications, essays, and test preparation' },
                    { emoji: '🌟', title: 'Internships', desc: 'Find and apply for internship opportunities in your areas of interest' },
                    { emoji: '⚙️', title: 'Account Settings', desc: `Manage your profile at ${user.email} and customize your preferences` }
                  ].map((item, index) => (
                    <li key={index} style={styles.tipItem}>
                      {item.emoji} {item.title}: {item.desc}
                    </li>
                  ))}
                </ul>
              </div>
              <div style={styles.feedbackSection}>
                <p style={styles.feedbackText}>
                  Have feedback or need help? We'd love to hear from you!
                </p>
                <a 
                  href="https://forms.gle/voaAD1mt6SK16m8YA" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  style={styles.feedbackButton}
                >
                  📝 Submit Feedback
                </a>
              </div>
            </div>
          </div>

          {/* Right Column (2/3) */}
          <div style={styles.rightColumn}>
            {/* My Courses Section */}
            <div style={styles.mainContainer}>
              <h2 style={styles.sectionHeader}>My Courses</h2>
              <div style={styles.coursesContainer}>
                {userCourses.length > 0 ? (
                  userCourses.map(course => (
                    <div 
                      key={course.id} 
                      style={{...styles.courseItem, cursor: 'pointer'}}
                      onClick={() => navigate(`/mycourses/${course.id}`)}
                    >
                      <div style={styles.courseLeft}>
                        <span style={styles.courseName}>{course.name}</span>
                        <span style={styles.courseSubject}>{course.subject}</span>
                      </div>
                      <div style={styles.courseProgress}>
                        {course.progress || 0}% Complete
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={styles.emptyMessage}>No courses added yet. Visit the My Courses tab to add courses.</p>
                )}
              </div>
            </div>

            {/* Compliance Status Section */}
            <div style={styles.mainContainer}>
              <h2 style={styles.sectionHeader}>Compliance Status</h2>
              <div style={styles.complianceContainer}>
                <div style={styles.complianceWrapper}>
                  <div style={{
                    ...styles.complianceStatus,
                    backgroundColor: isCompliant ? '#4CAF50' : '#FF5252',
                  }}>
                    <span style={styles.complianceIcon}>
                      {isCompliant ? '✓' : '!'}
                    </span>
                    {isCompliant ? 'Compliant' : 'Not Compliant'}
                  </div>
                  {!isCompliant && (
                    <div style={styles.complianceReason}>
                      <span style={styles.warningIcon}>⚠️</span>
                      {complianceReason}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={styles.mainContainer}>
              <h2 style={styles.sectionHeader}>YourEDU ToDo's</h2>
              <div style={styles.todoContainer}>
                {systemTodos.map((todo, index) => (
                  <div key={index} style={styles.todoItem}>
                    <span style={styles.todoText}>{todo.text}</span>
                    <span style={styles.todoDueDate}>{formatDeadline(todo.dueDate)}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
  },
  contentWrapper: {
    // Remove padding here since it's handled by App.js pageContainer
  },
  splitLayout: {
    display: 'flex',
    gap: '24px',
  },
  leftColumn: {
    flex: 2,
  },
  rightColumn: {
    flex: 1,
  },
  mainContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(49, 130, 206, 0.1)',
    marginBottom: '24px',
  },
  welcomeContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  tipsContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  helpText: {
    padding: '24px 32px',
    margin: 0,
    borderBottom: '1px solid #edf2f7',
    fontSize: '15px',
    color: '#2d3748',
    lineHeight: '1.5',
    fontWeight: '500',
    backgroundColor: 'transparent',
  },
  feedbackContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    transition: 'all 0.3s ease',
    border: '1px solid rgba(49, 130, 206, 0.1)',
  },
  editOnboardingButtonContainer: {
    padding: '24px 32px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
  },
  rightColumn: {
    flex: '1',
    maxWidth: '33.333%',
  },
  mainContentBox: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  welcomeText: {
    fontSize: '32px',
    fontWeight: 'bold',
    margin: '0 0 5px 0',
    lineHeight: '1.1',
  },
  emailText: {
    fontSize: '18px',
    marginBottom: '20px',
    color: '#555',
  },
  introText: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginTop: '0',
    marginBottom: '20px',
    color: '#333',
  },
  tipsContainer: {
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '20px',
    backgroundColor: '#f5f5f5',
  },
  tipsHeader: {
    fontSize: '24px',
    marginBottom: '10px',
  },
  tipsList: {
    listStyle: 'none',
    padding: '0',
    margin: '0',
  },
  tipItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '8px',
    padding: '8px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '1.4',
  },
  helpText: {
    fontSize: '16px',
    textAlign: 'center',
  },
  feedbackContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  feedbackText: {
    fontSize: '16px',
    marginBottom: '10px',
  },
  feedbackLink: {
    display: 'inline-block',
    padding: '15px',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#00356b',
    color: '#FFFFFF',
    textDecoration: 'none',
    borderRadius: '5px',
  },
  link: {
    color: '#00356b',
    textDecoration: 'none',
  },
  welcomeHeader: {
    fontSize: '26px',
    fontWeight: 'bold',
    margin: '0 0 10px 0',
    color: '#00356b',
  },
  header: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#00356b',
  },
  todoContainer: {
    marginBottom: '20px',
  },
  todoList: {
    listStyleType: 'none',
    paddingLeft: '0',
  },
  todoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  todoInput: {
    width: '80%',
    padding: '10px',
    marginRight: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#007BFF',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  deleteButton: {
    padding: '10px',
    backgroundColor: '#FF0000',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  calendarContainer: {
    marginTop: '20px',
  },
  editOnboardingButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '20px',
  },
  editOnboardingButton: {
    padding: '10px 20px',
    backgroundColor: '#00356b',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  deadlinesContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '28px 32px',
    marginBottom: '24px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  deadlinesText: {
    fontSize: '16px',
    color: '#555',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
  searchButton: {
    backgroundColor: '#00356b',
    color: '#fff',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  adminProgressContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '28px 32px',
    marginBottom: '24px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
  progressItem: {
    marginBottom: '16px',
  },
  progressHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '6px',
  },
  progressLabel: {
    fontSize: '14px',
    color: '#555',
    fontWeight: '500',
  },
  progressBarContainer: {
    width: '100%',
    height: '8px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#00356b',
    borderRadius: '4px',
    transition: 'width 0.3s ease',
  },
  progressText: {
    fontSize: '14px',
    color: '#555',
    fontWeight: '500',
  },
  deadlinesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  deadlineItem: {
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#f8f9fa',
  },
  schoolName: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#00356b',
    marginBottom: '5px',
  },
  deadline: {
    fontSize: '14px',
    color: '#555',
    margin: '3px 0',
  },
  deadlineLabel: {
    fontWeight: 'bold',
    color: '#333',
    marginRight: '8px',
  },
  welcomeContainer: {
    backgroundColor: '#fff',
    borderRadius: '12px 12px 0 0',
    padding: '24px 32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginBottom: '0',
  },
  welcomeHeader: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1a365d',
    marginBottom: '12px',
    textAlign: 'center',
    fontFamily: "'Inter', sans-serif",
  },
  introText: {
    fontSize: '15px',
    color: '#1a365d',
    lineHeight: '1.5',
    textAlign: 'center',
    fontFamily: "'Inter', sans-serif",
    marginBottom: '0',
    fontWeight: '500',
  },
  tipsContainer: {
    backgroundColor: '#fff',
    borderRadius: '0',
    padding: '28px 32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    marginBottom: '0',
    borderTop: '1px solid #edf2f7',
  },
  tipsHeader: {
    fontSize: '19px',
    fontWeight: '600',
    color: '#2D3748',
    marginBottom: '6px',
    fontFamily: "'Inter', sans-serif",
  },
  tipsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  tipItem: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: '8px',
    padding: '8px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '1.4',
  },
  tipIcon: {
    fontSize: '16px',
    marginRight: '12px',
    marginTop: '2px',
  },
  tipText: {
    flex: 1,
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.4',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
  },
  feedbackContainer: {
    backgroundColor: '#fff',
    borderRadius: '0 0 12px 12px',
    padding: '24px 32px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    borderTop: '1px solid #edf2f7',
  },
  feedbackText: {
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.5',
    marginBottom: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
  },
  feedbackLink: {
    display: 'inline-block',
    backgroundColor: '#00356b',
    color: '#ffffff',
    padding: '12px 24px',
    borderRadius: '6px',
    textDecoration: 'none',
    fontWeight: '600',
    fontSize: '14px',
    marginTop: '8px',
  },
  helpText: {
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.5',
    marginBottom: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto 24px',
  },
  feedbackText: {
    fontSize: '14px',
    color: '#1a365d',
    lineHeight: '1.5',
    marginBottom: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    textAlign: 'center',
    maxWidth: '600px',
    margin: '0 auto 24px',
  },
  feedbackLink: {
    display: 'block',
    width: 'fit-content',
    backgroundColor: '#1a365d',
    color: '#ffffff',
    padding: '10px 24px',
    borderRadius: '6px',
    textDecoration: 'none',
    fontWeight: '600',
    margin: '0 auto',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#2c5282',
      transform: 'translateY(-1px)',
    },
  },
  mainContainer: {
    backgroundColor: '#ffffff',
    padding: '16px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(49, 130, 206, 0.1)',
    marginBottom: '16px',
  },
  welcomeHeader: {
    fontSize: '22px',
    fontWeight: '600',
    color: '#00356b',
    marginBottom: '8px',
    textAlign: 'center',
  },
  introText: {
    fontSize: '14px',
    marginBottom: '16px',
    lineHeight: '1.4',
  },
  resourceHeader: {
    fontSize: '18px',
    marginBottom: '8px',
    color: '#2D3748',
  },
  resourceText: {
    fontSize: '14px',
    marginBottom: '16px',
    lineHeight: '1.4',
  },
  tipsHeader: {
    fontSize: '19px',
    marginBottom: '6px',
    color: '#2D3748',
    fontWeight: '600',
  },
  tipsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    fontSize: '14px',
    lineHeight: '1.4',
  },
  tipsList: {
    'li': {
      marginBottom: '6px',
    }
  },
  feedbackSection: {
    marginTop: '16px',
    textAlign: 'center',
    borderTop: '1px solid #edf2f7',
    paddingTop: '16px',
  },
  feedbackText: {
    fontSize: '14px',
    marginBottom: '12px',
  },
  feedbackButton: {
    display: 'inline-block',
    padding: '8px 16px',
    backgroundColor: '#00356b',
    color: '#fff',
    borderRadius: '4px',
    textDecoration: 'none',
    fontSize: '14px',
  },
  courseItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    marginBottom: '8px',
    transition: 'all 0.2s ease',
    border: '1px solid #e2e8f0',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  courseLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  courseName: {
    fontSize: '15px',
    fontWeight: '600',
    color: '#2d3748',
  },
  courseSubject: {
    fontSize: '13px',
    color: '#718096',
  },
  courseProgress: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#4CAF50',
    backgroundColor: '#f0fff4',
    padding: '4px 12px',
    borderRadius: '12px',
  },
  complianceContainer: {
    padding: '16px',
  },
  complianceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  complianceStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    borderRadius: '8px',
    color: 'white',
    fontWeight: '500',
    fontSize: '15px',
  },
  complianceIcon: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  complianceReason: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px',
    backgroundColor: '#fff4e5',
    borderRadius: '8px',
    color: '#b45309',
    fontSize: '14px',
  },
  warningIcon: {
    fontSize: '16px',
  },
  emptyMessage: {
    color: '#718096',
    textAlign: 'center',
    padding: '20px',
    fontStyle: 'italic',
  },
  pageContainer: {
    width: '100%',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
  },
  splitLayout: {
    flex: 1,
    padding: '24px',
    display: 'flex',
    gap: '24px',
  },
  leftColumn: {
    flex: '2',
  },
  rightColumn: {
    flex: '1',
  },
  mainContainer: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    border: '1px solid rgba(49, 130, 206, 0.1)',
    marginBottom: '24px',
  },
  sectionHeader: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#2D3748',
    marginBottom: '16px',
  },
  emptyDeadlines: {
    textAlign: 'center',
    padding: '20px',
  },
  searchButton: {
    backgroundColor: '#00356b',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '16px',
  },
};

// Add font import
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

export default Home;

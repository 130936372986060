import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../utils/AuthContext';
import PageHeader from '../components/PageHeader';
import { FaThumbsUp, FaComment, FaHashtag, FaEdit, FaUser, FaGraduationCap, FaBook, FaLaptop, FaPencilAlt, FaBrain, FaChalkboardTeacher, FaUserGraduate, FaUserNinja } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';

const Community = () => {
  const { user } = useAuth();
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [hashtags, setHashtags] = useState([]);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [displayName, setDisplayName] = useState(user?.displayName || 'Anonymous');
  const [selectedAvatar, setSelectedAvatar] = useState('FaUser');
  const [likedPosts, setLikedPosts] = useState(new Set());
  const [showComments, setShowComments] = useState(new Set());
  const [commentInputs, setCommentInputs] = useState({});

  const categories = [
    'All',
    'General',
    'Getting Started',
    'Dual Enrollment',
    'College',
    'AP Courses',
    'Resources'
  ];

  // Available avatars array
  const avatarOptions = [
    { icon: FaUser, name: 'FaUser' },
    { icon: FaGraduationCap, name: 'FaGraduationCap' },
    { icon: FaBook, name: 'FaBook' },
    { icon: FaLaptop, name: 'FaLaptop' },
    { icon: FaPencilAlt, name: 'FaPencilAlt' },
    { icon: FaBrain, name: 'FaBrain' },
    { icon: FaChalkboardTeacher, name: 'FaChalkboardTeacher' },
    { icon: FaUserGraduate, name: 'FaUserGraduate' },
    { icon: FaUserNinja, name: 'FaUserNinja' }
  ];

  // Temporary mock data - replace with actual API calls later
  useEffect(() => {
    setPosts([
      {
        id: 1,
        author: 'Sarah Johnson',
        avatarIcon: 'FaGraduationCap',
        content: 'Any tips for starting dual enrollment? #dualenrollment',
        likes: 15,
        comments: [],
        commentCount: 8,
        hashtags: ['dualenrollment'],
        timestamp: new Date('2024-03-15'),
        category: 'Dual Enrollment'
      },
      // Add more mock posts
    ]);
  }, []);

  const handlePostSubmit = (e) => {
    e.preventDefault();
    if (!newPost.trim()) return;

    const hashtagRegex = /#[a-zA-Z0-9]+/g;
    const extractedHashtags = newPost.match(hashtagRegex) || [];
    
    const newPostObj = {
      id: posts.length + 1,
      author: displayName,
      avatarIcon: selectedAvatar,
      content: newPost,
      likes: 0,
      comments: [],
      commentCount: 0,
      hashtags: extractedHashtags.map(tag => tag.slice(1)),
      timestamp: new Date(),
      category: selectedCategory
    };

    setPosts([newPostObj, ...posts]);
    setNewPost('');
  };

  const handleNameSubmit = (e) => {
    e.preventDefault();
    setIsEditingProfile(false);
    // You would typically update the user's displayName in your auth system here
  };

  const handleLike = (postId) => {
    setPosts(posts.map(post => {
      if (post.id === postId) {
        return {
          ...post,
          likes: likedPosts.has(postId) ? post.likes - 1 : post.likes + 1
        };
      }
      return post;
    }));
    
    setLikedPosts(prev => {
      const newLiked = new Set(prev);
      if (newLiked.has(postId)) {
        newLiked.delete(postId);
      } else {
        newLiked.add(postId);
      }
      return newLiked;
    });
  };

  const handleComment = (postId) => {
    if (!commentInputs[postId]?.trim()) return;
    
    setPosts(posts.map(post => {
      if (post.id === postId) {
        return {
          ...post,
          comments: [...(post.comments || []), {
            id: Date.now(),
            author: displayName,
            avatarIcon: selectedAvatar,
            content: commentInputs[postId],
            timestamp: new Date()
          }]
        };
      }
      return post;
    }));
    
    setCommentInputs(prev => ({
      ...prev,
      [postId]: ''
    }));
  };

  return (
    <div style={styles.container}>
      <PageHeader title="Community" />
      
      <div style={styles.content}>
        {/* User Profile Section */}
        <div style={styles.profileSection}>
          {isEditingProfile ? (
            <>
              <div style={styles.avatarSelection}>
                {avatarOptions.map(({ icon: Icon, name }) => (
                  <button
                    key={name}
                    onClick={() => setSelectedAvatar(name)}
                    style={{
                      ...styles.avatarOption,
                      backgroundColor: selectedAvatar === name ? '#00356b' : 'transparent',
                      color: selectedAvatar === name ? '#ffffff' : '#00356b',
                    }}
                  >
                    <Icon size={24} />
                  </button>
                ))}
              </div>
              <div style={styles.profileInfo}>
                <form onSubmit={handleNameSubmit} style={styles.nameForm}>
                  <input
                    type="text"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    style={styles.nameInput}
                    autoFocus
                  />
                  <button type="submit" style={styles.saveButton}>
                    Save
                  </button>
                </form>
              </div>
            </>
          ) : (
            <>
              <div style={styles.currentAvatar}>
                {React.createElement(avatarOptions.find(opt => opt.name === selectedAvatar).icon, { size: 24 })}
              </div>
              <div style={styles.profileInfo}>
                <div style={styles.nameDisplay}>
                  <span style={styles.userName}>{displayName}</span>
                  <button 
                    onClick={() => setIsEditingProfile(true)}
                    style={styles.editButton}
                  >
                    <FaEdit />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Categories */}
        <div style={styles.categories}>
          {categories.map(category => (
            <button
              key={category}
              style={{
                ...styles.categoryButton,
                backgroundColor: selectedCategory === category ? '#00356b' : '#fff',
                color: selectedCategory === category ? '#fff' : '#00356b'
              }}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* New Post Form */}
        <form onSubmit={handlePostSubmit} style={styles.postForm}>
          <textarea
            value={newPost}
            onChange={(e) => setNewPost(e.target.value)}
            placeholder="Share your thoughts, questions, or resources... Use #hashtags!"
            style={styles.postInput}
          />
          <button type="submit" style={styles.submitButton}>
            Post
          </button>
        </form>

        {/* Posts Feed */}
        <div style={styles.feed}>
          {posts
            .filter(post => selectedCategory === 'All' || post.category === selectedCategory)
            .map(post => (
              <div key={post.id} style={styles.post}>
                <div style={styles.postHeader}>
                  <div style={styles.avatar}>
                    {React.createElement(
                      avatarOptions.find(opt => opt.name === post.avatarIcon)?.icon || FaUser,
                      { size: 24 }
                    )}
                  </div>
                  <div style={styles.postMeta}>
                    <span style={styles.authorName}>{post.author}</span>
                    <span style={styles.timestamp}>
                      {post.timestamp.toLocaleDateString()}
                    </span>
                  </div>
                </div>
                <p style={styles.postContent}>{post.content}</p>
                <div style={styles.postActions}>
                  <button 
                    onClick={() => handleLike(post.id)}
                    style={{
                      ...styles.actionButton,
                      color: likedPosts.has(post.id) ? '#00356b' : '#4a5568',
                      backgroundColor: likedPosts.has(post.id) ? '#ebf8ff' : '#f7fafc',
                    }}
                  >
                    <FaThumbsUp /> {post.likes}
                  </button>
                  <button 
                    onClick={() => setShowComments(prev => {
                      const newShow = new Set(prev);
                      if (newShow.has(post.id)) {
                        newShow.delete(post.id);
                      } else {
                        newShow.add(post.id);
                      }
                      return newShow;
                    })}
                    style={styles.actionButton}
                  >
                    <FaComment /> {post.comments.length}
                  </button>
                  {post.hashtags.map(tag => (
                    <span key={tag} style={styles.hashtag}>
                      <FaHashtag />{tag}
                    </span>
                  ))}
                </div>
                
                {showComments.has(post.id) && (
                  <div style={styles.commentsSection}>
                    {Array.isArray(post.comments) && post.comments.map(comment => (
                      <div key={comment.id} style={styles.comment}>
                        <div style={styles.commentHeader}>
                          <div style={styles.commentAvatar}>
                            {React.createElement(
                              avatarOptions.find(opt => opt.name === comment.avatarIcon)?.icon || FaUser,
                              { size: 16 }
                            )}
                          </div>
                          <span style={styles.commentAuthor}>{comment.author}</span>
                          <span style={styles.commentTime}>
                            {comment.timestamp.toLocaleDateString()}
                          </span>
                        </div>
                        <p style={styles.commentContent}>{comment.content}</p>
                      </div>
                    ))}
                    <div style={styles.commentForm}>
                      <input
                        type="text"
                        value={commentInputs[post.id] || ''}
                        onChange={(e) => setCommentInputs(prev => ({
                          ...prev,
                          [post.id]: e.target.value
                        }))}
                        placeholder="Write a comment..."
                        style={styles.commentInput}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleComment(post.id);
                          }
                        }}
                      />
                      <button 
                        onClick={() => handleComment(post.id)}
                        style={styles.commentButton}
                      >
                        Post
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    padding: '24px',
    position: 'relative',
  },
  content: {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  profileSection: {
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  avatarSelection: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    maxWidth: '300px',
  },
  avatarOption: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid #00356b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    padding: 0,
    '&:hover': {
      backgroundColor: '#00356b',
      color: '#ffffff',
    },
  },
  userName: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#2d3748',
  },
  categories: {
    display: 'flex',
    gap: '12px',
    flexWrap: 'wrap',
    padding: '16px',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  categoryButton: {
    padding: '8px 16px',
    borderRadius: '20px',
    border: '1px solid #00356b',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#00356b',
      color: '#ffffff',
    },
  },
  postForm: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  postInput: {
    width: '100%',
    minHeight: '120px',
    padding: '16px',
    borderRadius: '8px',
    border: '1px solid #e2e8f0',
    marginBottom: '16px',
    fontSize: '16px',
    resize: 'vertical',
    '&:focus': {
      outline: 'none',
      borderColor: '#00356b',
    },
  },
  submitButton: {
    backgroundColor: '#00356b',
    color: '#ffffff',
    padding: '10px 24px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
  feed: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  post: {
    backgroundColor: '#ffffff',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  postHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '16px',
  },
  avatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid #00356b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00356b',
    backgroundColor: '#f7fafc',
  },
  postMeta: {
    display: 'flex',
    flexDirection: 'column',
  },
  authorName: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#2d3748',
  },
  timestamp: {
    fontSize: '14px',
    color: '#718096',
  },
  postContent: {
    fontSize: '16px',
    color: '#2d3748',
    lineHeight: '1.6',
    marginBottom: '16px',
    whiteSpace: 'pre-wrap',
  },
  postActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    borderTop: '1px solid #e2e8f0',
    paddingTop: '16px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    padding: '8px 12px',
    borderRadius: '6px',
    border: 'none',
    backgroundColor: '#f7fafc',
    color: '#4a5568',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#edf2f7',
    },
  },
  hashtag: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
    padding: '4px 8px',
    borderRadius: '4px',
    backgroundColor: '#ebf8ff',
    color: '#2b6cb0',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#bee3f8',
    },
  },
  profileInfo: {
    flex: 1,
  },
  nameForm: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  nameInput: {
    padding: '8px 12px',
    borderRadius: '6px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
    '&:focus': {
      outline: 'none',
      borderColor: '#00356b',
    },
  },
  saveButton: {
    backgroundColor: '#00356b',
    color: '#ffffff',
    padding: '8px 16px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
  nameDisplay: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  editButton: {
    background: 'none',
    border: 'none',
    color: '#718096',
    cursor: 'pointer',
    padding: '4px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    transition: 'color 0.2s',
    '&:hover': {
      color: '#00356b',
    },
  },
  currentAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid #00356b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00356b',
  },
  commentsSection: {
    marginTop: '16px',
    borderTop: '1px solid #e2e8f0',
    paddingTop: '16px',
  },
  comment: {
    padding: '12px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    marginBottom: '8px',
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '4px',
  },
  commentAvatar: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: '1px solid #00356b',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00356b',
    backgroundColor: '#fff',
  },
  commentAuthor: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#2d3748',
  },
  commentTime: {
    fontSize: '12px',
    color: '#718096',
  },
  commentContent: {
    fontSize: '14px',
    color: '#4a5568',
    marginLeft: '32px',
  },
  commentForm: {
    display: 'flex',
    gap: '8px',
    marginTop: '12px',
  },
  commentInput: {
    flex: 1,
    padding: '8px 12px',
    borderRadius: '20px',
    border: '1px solid #e2e8f0',
    fontSize: '14px',
    '&:focus': {
      outline: 'none',
      borderColor: '#00356b',
    },
  },
  commentButton: {
    backgroundColor: '#00356b',
    color: '#ffffff',
    padding: '6px 16px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
};

export default Community; 
import React, { useState } from 'react';
import { useAuth } from '../utils/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import babyYoda from '../assets/jedi/baby.png';
import babyColorful from '../assets/jedi/baby-colorful.png';
import babyForce from '../assets/jedi/baby-force.png';
import babyHeart from '../assets/jedi/baby-heart.png';
import babyLightsaber from '../assets/jedi/baby-lightsaber.png';
import babySticker from '../assets/jedi/baby-sticker.png';
import teenEating from '../assets/jedi/teen-eating.png';
import oldForce from '../assets/jedi/old-force.png';
import oldLightsaber from '../assets/jedi/old-lightsaber.png';
import oldThumbsup from '../assets/jedi/old-thumbsup.png';
import oldAttacking from '../assets/jedi/old-attacking.jpg';
import oldGrumpy from '../assets/jedi/old-grumpy.jpg';
import oldCircle from '../assets/jedi/old-circleportrait.png';
import youngYoda from '../assets/jedi/young.png';
import adultEating from '../assets/jedi/adult-eating.webp';
import lego from '../assets/jedi/lego.png';
import commonAppLogo from '../assets/common-app.png';

const Login = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { login, register } = useAuth();
  const userType = localStorage.getItem('userType');
  const [loadingStage, setLoadingStage] = useState('Establishing connection...');

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    try {
      setLoadingStage('Establishing connection...');
      await new Promise(resolve => setTimeout(resolve, 600));
      
      setLoadingStage('Verifying credentials...');
      const userData = await login(email, password);
      await new Promise(resolve => setTimeout(resolve, 600));
      
      setLoadingStage('Loading your profile...');
      await new Promise(resolve => setTimeout(resolve, 600));
      
      setLoadingStage('Preparing your dashboard...');
      await new Promise(resolve => setTimeout(resolve, 600));
      
      setLoadingStage('Almost there...');
      await new Promise(resolve => setTimeout(resolve, 600));

      if (!userData) {
        throw new Error('Invalid login response');
      }

      const userType = localStorage.getItem('userType');

      if (userType === 'college') {
        const hasCompletedOnboarding = localStorage.getItem('collegeOnboardingCompleted');
        navigate(hasCompletedOnboarding ? '/' : '/onboarding/college');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.log('Login error:', error);
      setErrorMessage(error.message || 'Failed to login. Please check your credentials.');
    } finally {
      setIsLoading(false);
      setLoadingStage('Establishing connection...');
    }
  };

  return (
    <div style={styles.background}>
      {userType === 'college' && (
        <div style={styles.partnershipSection}>
          <div style={styles.partnerLogos}>
            <img src={commonAppLogo} alt="Common App Logo" style={styles.commonAppLogo} />
            <img src={logo} alt="YourEDU Logo" style={styles.eduLogo} />
          </div>
        </div>
      )}
      
      {userType === 'highschool' && (
        <>
          <div style={styles.textSection}>
            <h2 style={styles.greetingText}>{getGreeting()}! ✨</h2>
            <h3 style={styles.welcomeText}>Welcome to your education journey</h3>
          </div>
          
          <div style={styles.progressionContainer}>
            <img 
              src={babyYoda} 
              alt="Beginner" 
              style={styles.yodaBaby} 
            />
            <img 
              src={youngYoda} 
              alt="Learning" 
              style={styles.yodaYoung} 
            />
            <img 
              src={oldForce} 
              alt="Growing" 
              style={styles.yodaAdult} 
            />
            <img 
              src={oldLightsaber} 
              alt="Master" 
              style={styles.yodaMaster} 
            />
          </div>
        </>
      )}
      
      <div style={styles.container}>
        <div style={styles.header}>
          <div style={styles.logoContainer}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <h1 style={styles.companyName}>YourEDU</h1>
          </div>
        </div>
        <div style={styles.formContainer}>
          <h2 style={styles.formTitle}>{isLogin ? 'Login' : 'Signup'}</h2>
          <h3 style={styles.userType}>
            {userType === 'highschool' ? 'High School' : userType === 'college' ? 'College' : 'Parent'}
          </h3>
          {errorMessage && <p style={styles.error}>{errorMessage}</p>}
          <form onSubmit={handleSubmit} style={styles.form}>
            {!isLogin && (
              <div style={styles.inputGroup}>
                <label style={styles.label}>Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  style={styles.input}
                />
              </div>
            )}
            <div style={styles.inputGroup}>
              <label style={styles.label}>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <div style={styles.inputGroup}>
              <label style={styles.label}>Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={styles.input}
              />
              {isLogin && (
                <div style={styles.extraOptions}>
                  <label style={styles.showPassword}>
                    <input
                      type="checkbox"
                      checked={showPassword}
                      onChange={(e) => setShowPassword(e.target.checked)}
                    />
                    Show password
                  </label>
                  <Link to="/password-reset" style={styles.forgotPassword}>Forgot password?</Link>
                </div>
              )}
            </div>
            {!isLogin && (
              <div style={styles.inputGroup}>
                <label style={styles.label}>Verify Password</label>
                <input
                  type="password"
                  value={verifyPassword}
                  onChange={(e) => setVerifyPassword(e.target.value)}
                  required
                  style={styles.input}
                />
              </div>
            )}
            <button type="submit" style={styles.button}>{isLogin ? 'Login' : 'Signup'}</button>
          </form>
          <button onClick={() => setIsLogin(!isLogin)} style={styles.signupButton}>
            {isLogin ? "Don't have an account? Click here to sign up" : "Already have an account? Click here to log in"}
          </button>
        </div>
      </div>
      
      {isLoading && (
        <div style={styles.loadingStages}>
          {loadingStage} ✨
        </div>
      )}
    </div>
  );
};

const styles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00356b',
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '70%',
    transform: 'translate(-50%, -50%)',
    width: '420px',
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '32px',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
    zIndex: 1,
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: '50px',
    marginRight: '10px',
  },
  companyName: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  formContainer: {
    width: '100%',
    textAlign: 'left',
  },
  formTitle: {
    fontSize: '28px',
    color: '#1a365d',
    marginBottom: '8px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '600',
    textAlign: 'center',
  },
  userType: {
    fontSize: '18px',
    color: '#4a5568',
    marginBottom: '24px',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#00356b',
  },
  input: {
    width: '100%',
    padding: '12px 16px',
    fontSize: '16px',
    border: '1.5px solid #e2e8f0',
    borderRadius: '8px',
    marginBottom: '16px',
    transition: 'border-color 0.2s ease',
    fontFamily: "'Inter', sans-serif",
    '&:focus': {
      borderColor: '#3182ce',
      outline: 'none',
    },
  },
  extraOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px',
  },
  showPassword: {
    display: 'flex',
    alignItems: 'center',
  },
  forgotPassword: {
    color: '#00356b',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    padding: '14px',
    backgroundColor: '#1a365d',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '600',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    fontFamily: "'Inter', sans-serif",
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#2c5282',
    },
  },
  signupButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: 'transparent',
    color: '#00356b',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
    textAlign: 'center',
  },
  loadingStages: {
    position: 'absolute',
    top: 'calc(50% + 340px)',
    left: '70%',
    transform: 'translateX(-50%)',
    color: 'white',
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
    animation: 'gentlePulse 1.5s ease-in-out infinite',
    zIndex: 2,
    textAlign: 'center',
    fontWeight: '500',
    textShadow: '0 0 10px rgba(255,255,255,0.2)',
    backgroundColor: 'rgba(0, 53, 107, 0.3)',
    padding: '10px 20px',
    borderRadius: '8px',
  },
  floatingEmojis: {
    position: 'absolute',
    fontSize: '32px',
    animation: 'float 3s ease-in-out infinite',
    zIndex: 1,
  },
  yodaImage: {
    objectFit: 'contain',
    filter: 'drop-shadow(0 0 8px rgba(255,255,255,0.3))',
    transition: 'transform 0.3s ease',
  },
  spinner: {
    width: '40px',
    height: '40px',
    border: '4px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '50%',
    borderTop: '4px solid white',
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' }
    }
  },
  textSection: {
    position: 'absolute',
    top: '200px',
    left: '8%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    zIndex: 1,
  },
  greetingText: {
    color: 'white',
    fontSize: '42px',
    fontWeight: 'bold',
    margin: 0,
    textShadow: '0 0 10px rgba(255,255,255,0.2)',
    textAlign: 'left',
  },
  welcomeText: {
    color: 'white',
    fontSize: '32px',
    margin: 0,
    textShadow: '0 0 10px rgba(255,255,255,0.2)',
    textAlign: 'left',
  },
  progressionContainer: {
    position: 'absolute',
    top: '400px',
    left: '8%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: '40px',
    zIndex: 1,
  },
  yodaBaby: {
    width: '80px',
    height: '80px',
    transition: 'transform 0.2s ease',
  },
  yodaYoung: {
    width: '100px',
    height: '100px',
    transition: 'transform 0.2s ease',
  },
  yodaAdult: {
    width: '120px',
    height: '120px',
    transition: 'transform 0.2s ease',
  },
  yodaMaster: {
    width: '160px',
    height: '180px',
    objectFit: 'cover',
    transition: 'transform 0.2s ease',
  },
  partnershipSection: {
    position: 'absolute',
    top: '50%',
    left: '8%',
    transform: 'translateY(-50%)',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    zIndex: 1,
    maxWidth: '45%',
  },

  partnerLogos: {
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
    justifyContent: 'center',
  },

  eduLogo: {
    width: '220px',
    height: 'auto',
    filter: 'drop-shadow(0 0 15px rgba(255,255,255,0.2))',
  },

  commonAppLogo: {
    width: '280px',
    height: 'auto',
    filter: 'drop-shadow(0 0 15px rgba(255,255,255,0.2))',
  },

  partnershipDivider: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },

  partnerText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    opacity: 0.9,
    fontFamily: "'Inter', sans-serif",
  },

  dividerLine: {
    width: '2px',
    height: '40px',
    background: 'linear-gradient(to bottom, transparent, white, transparent)',
  },

  partnershipText: {
    color: 'white',
    fontSize: '20px',
    maxWidth: '460px',
    lineHeight: '1.6',
    textAlign: 'left',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '400',
    opacity: 0.95,
    letterSpacing: '0.3px',
  },
};

const styleSheet = document.createElement('style');
styleSheet.textContent = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(-10px) rotate(3deg); }
  }
  
  @keyframes bounce {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.8; transform: scale(1.05); }
  }
  
  @keyframes sparkle {
    0%, 100% { filter: brightness(1); }
    50% { filter: brightness(1.3); }
  }
  
  @keyframes gentleFloat {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  @keyframes gentlePulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.6; }
  }
`;
document.head.appendChild(styleSheet);

// Add font import to your HTML head
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

export default Login;

import React, { useState, useEffect } from 'react';
import { FiDownload, FiChevronLeft, FiChevronRight, FiTrash2 } from 'react-icons/fi';

const WeeklySchedule = () => {
  const [currentTerm, setCurrentTerm] = useState('Fall 2024-2025');
  const [selectedCourses, setSelectedCourses] = useState([]);

  const timeSlots = [
    '8 AM', '9 AM', '10 AM', '11 AM', '12 PM',
    '1 PM', '2 PM', '3 PM', '4 PM', '5 PM'
  ];
  
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];

  const terms = [
    'Fall 2024-2025',
    'Winter 2024-2025',
    'Spring 2024-2025',
    'Summer 2024-2025'
  ];

  useEffect(() => {
    const savedScheduleData = JSON.parse(localStorage.getItem('scheduleData') || '{}');
    if (savedScheduleData[currentTerm]) {
      const uniqueCourses = savedScheduleData[currentTerm].filter((course, index, self) =>
        index === self.findIndex((c) => c.id === course.id)
      );
      setSelectedCourses(uniqueCourses);
    } else {
      setSelectedCourses([]);
    }
  }, [currentTerm]);

  const handleTermChange = (direction) => {
    // Implement term switching logic
    const currentIndex = terms.indexOf(currentTerm);
    if (direction === 'next' && currentIndex < terms.length - 1) {
      setCurrentTerm(terms[currentIndex + 1]);
    } else if (direction === 'prev' && currentIndex > 0) {
      setCurrentTerm(terms[currentIndex - 1]);
    }
  };

  const toggleCourse = (courseId) => {
    setSelectedCourses(courses => 
      courses.map(course => 
        course.id === courseId 
          ? { ...course, selected: !course.selected }
          : course
      )
    );
  };

  const downloadSchedule = () => {
    const icsContent = generateICS(selectedCourses);
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `schedule_${currentTerm.replace(/\s+/g, '_')}.ics`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderCourseBlocks = () => {
    return selectedCourses.filter(course => course.selected).map((course) => {
      return course.days.map(day => {
        const startPosition = timeToGridPosition(course.startTime);
        const endPosition = timeToGridPosition(course.endTime);
        const duration = endPosition - startPosition;
        
        // Convert day format (e.g., "Tuesday" to "Tue")
        const shortDay = day.substring(0, 3);
        const dayIndex = days.indexOf(shortDay);
        
        if (dayIndex === -1) return null;

        return (
          <div
            key={`${course.id}-${shortDay}`}
            style={{
              position: 'absolute',
              top: `${startPosition}px`,
              left: `${(dayIndex * 20)}%`,
              width: '19%',
              height: `${duration}px`,
              backgroundColor: course.color || '#4299E1',
              borderRadius: '4px',
              padding: '8px',
              color: 'white',
              fontSize: '12px',
              overflow: 'hidden',
              zIndex: 1
            }}
          >
            <div style={{ fontWeight: 'bold' }}>{course.id}</div>
            <div>{course.name}</div>
            <div>{course.startTime}-{course.endTime}</div>
          </div>
        );
      });
    });
  };

  const handleDeleteCourse = (courseId) => {
    // Remove from selectedCourses state
    setSelectedCourses(prev => prev.filter(course => course.id !== courseId));
    
    // Remove from localStorage
    const savedScheduleData = JSON.parse(localStorage.getItem('scheduleData') || '{}');
    if (savedScheduleData[currentTerm]) {
      savedScheduleData[currentTerm] = savedScheduleData[currentTerm].filter(
        course => course.id !== courseId
      );
      localStorage.setItem('scheduleData', JSON.stringify(savedScheduleData));
    }
  };

  return (
    <div style={styles.container}>
      {/* Schedule Grid */}
      <div style={styles.scheduleGrid}>
        <div style={styles.timeColumn}>
          {timeSlots.map(time => (
            <div key={time} style={styles.timeSlot}>{time}</div>
          ))}
        </div>
        
        <div style={styles.daysGrid}>
          {/* Days Header */}
          <div style={styles.daysHeader}>
            {days.map(day => (
              <div key={day} style={styles.dayCell}>{day}</div>
            ))}
          </div>
          
          {/* Schedule Cells */}
          <div style={styles.scheduleBody}>
            {renderCourseBlocks()}
          </div>
        </div>
      </div>

      {/* Term Navigation */}
      <div style={styles.termNavigation}>
        <button onClick={() => handleTermChange('prev')} style={styles.navButton}>
          <FiChevronLeft />
        </button>
        <span style={styles.termText}>{currentTerm}</span>
        <button onClick={() => handleTermChange('next')} style={styles.navButton}>
          <FiChevronRight />
        </button>
      </div>

      {/* Term Stats */}
      <div style={styles.termStats}>
        <div>Pinned units: 17</div>
        <div>Estimated weekly workload: 60 hours</div>
      </div>

      {/* Course List */}
      <div style={styles.courseList}>
        {selectedCourses.map(course => (
          <div key={course.id} style={styles.courseItem}>
            <input
              type="checkbox"
              checked={course.selected}
              onChange={() => toggleCourse(course.id)}
            />
            <div style={styles.courseInfo}>
              <div style={styles.courseHeader}>
                <span style={styles.courseId}>{course.id}</span>
                <span style={styles.courseName}>{course.name}</span>
              </div>
              <div style={styles.courseDetails}>
                Section {course.section} - {course.days.join(',')}
                <br />
                {course.startTime}-{course.endTime}
              </div>
            </div>
            <button 
              onClick={() => handleDeleteCourse(course.id)}
              style={styles.deleteButton}
            >
              <FiTrash2 />
            </button>
          </div>
        ))}
      </div>

      {/* Download Button */}
      <button onClick={downloadSchedule} style={styles.downloadButton}>
        <FiDownload /> Download Schedule
      </button>
    </div>
  );
};

const timeToGridPosition = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const startOfDay = 8; // 8 AM is our start time
  const position = ((hours - startOfDay) * 60) + minutes;
  return position;
};

const generateICS = (courses) => {
  let icsContent = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//YourEdu//Schedule//EN'
  ];

  courses.forEach(course => {
    if (course.selected) {
      course.days.forEach(day => {
        icsContent = icsContent.concat([
          'BEGIN:VEVENT',
          `SUMMARY:${course.id} - ${course.name}`,
          `DESCRIPTION:Section ${course.section}`,
          // Add more event details here
          'END:VEVENT'
        ]);
      });
    }
  });

  icsContent.push('END:VCALENDAR');
  return icsContent.join('\r\n');
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: 'white',
    color: '#333',
    borderRadius: '8px',
  },
  scheduleGrid: {
    display: 'grid',
    gridTemplateColumns: '80px 1fr',
    gap: '1px',
    backgroundColor: '#e2e8f0',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  timeColumn: {
    display: 'grid',
    gridTemplateRows: 'repeat(10, 60px)',
    backgroundColor: '#f8f9fa',
  },
  timeSlot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    borderBottom: '1px solid #e2e8f0',
    color: '#4a5568',
    fontSize: '14px',
  },
  daysGrid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
  },
  daysHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e2e8f0',
  },
  dayCell: {
    padding: '12px',
    textAlign: 'center',
    color: '#2d3748',
    fontWeight: '500',
    fontSize: '16px',
  },
  scheduleBody: {
    display: 'grid',
    gridTemplateRows: 'repeat(10, 60px)',
    gridTemplateColumns: 'repeat(5, 1fr)',
    position: 'relative',
    backgroundColor: 'white',
  },
  termNavigation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    marginTop: '24px',
    marginBottom: '16px',
  },
  navButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#4a5568',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f7fafc',
    },
  },
  termText: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#2d3748',
  },
  termStats: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    marginBottom: '24px',
    color: '#4a5568',
  },
  courseList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginBottom: '24px',
  },
  courseItem: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
    padding: '12px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    border: '1px solid #e2e8f0',
  },
  courseInfo: {
    flex: 1,
  },
  courseHeader: {
    display: 'flex',
    gap: '8px',
    marginBottom: '4px',
  },
  courseId: {
    fontWeight: '600',
    color: '#00356b',
  },
  courseName: {
    color: '#2d3748',
  },
  courseDetails: {
    fontSize: '14px',
    color: '#4a5568',
  },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px 24px',
    backgroundColor: '#00356b',
    color: '#fff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
  deleteButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#4a5568',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f7fafc',
    },
  },
};

export default WeeklySchedule; 
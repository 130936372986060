import React from 'react';
import PageHeader from '../components/PageHeader';

const Extracurriculars = () => {
  return (
    <div style={styles.container}>
      <PageHeader title="Extracurricular Activities" />
      
      <div style={styles.searchBarContainer}>
        {/* You can add a search bar here later when needed */}
      </div>

      <div style={styles.mainContent}>
        <div style={styles.contentBox}>
          <p style={styles.comingSoon}>Coming Soon!</p>
          <p style={styles.message}>
            We are putting together the largest homeschool extracurricular repository to help students find and apply for Summer programs, internships, jobs, community/volunteer work, tutoring, etc.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    padding: '24px',
    position: 'relative',
  },
  searchBarContainer: {
    padding: '24px',
    paddingBottom: '0',
  },
  mainContent: {
    display: 'flex',
    gap: '24px',
    padding: '24px',
  },
  contentBox: {
    flex: 1,
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    textAlign: 'center',
  },
  comingSoon: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: '16px',
  },
  message: {
    fontSize: '18px',
    color: '#555',
    lineHeight: '1.5',
  },
};

export default Extracurriculars;

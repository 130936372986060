import React, { useState } from 'react';

const TodoList = ({ todos, onEdit }) => {
  const [newTodo, setNewTodo] = useState('');
  const [isEditing, setIsEditing] = useState(null);
  const [editText, setEditText] = useState('');

  const handleAddTodo = () => {
    if (!newTodo.trim()) return;
    const newTodoItem = {
      id: Date.now(),
      text: newTodo,
      completed: false,
      date: new Date().toISOString()
    };
    onEdit([...todos, newTodoItem]);
    setNewTodo('');
  };

  const handleToggleComplete = (todoId) => {
    const updatedTodos = todos.map(todo =>
      todo.id === todoId ? { ...todo, completed: !todo.completed } : todo
    );
    onEdit(updatedTodos);
  };

  const handleDelete = (todoId) => {
    onEdit(todos.filter(todo => todo.id !== todoId));
  };

  const handleEdit = (todo) => {
    setIsEditing(todo.id);
    setEditText(todo.text);
  };

  const handleSaveEdit = (todoId) => {
    const updatedTodos = todos.map(todo =>
      todo.id === todoId ? { ...todo, text: editText } : todo
    );
    onEdit(updatedTodos);
    setIsEditing(null);
  };

  return (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={newTodo}
          onChange={(e) => setNewTodo(e.target.value)}
          placeholder="Add new todo..."
          style={styles.input}
          onKeyPress={(e) => e.key === 'Enter' && handleAddTodo()}
        />
        <button onClick={handleAddTodo} style={styles.addButton}>
          Add
        </button>
      </div>

      <div style={styles.todoList}>
        {todos.map(todo => (
          <div key={todo.id} style={styles.todoItem}>
            <div style={styles.todoContent}>
              <input
                type="checkbox"
                checked={todo.completed}
                onChange={() => handleToggleComplete(todo.id)}
                style={styles.checkbox}
              />
              {isEditing === todo.id ? (
                <input
                  type="text"
                  value={editText}
                  onChange={(e) => setEditText(e.target.value)}
                  style={styles.editInput}
                  onKeyPress={(e) => e.key === 'Enter' && handleSaveEdit(todo.id)}
                  autoFocus
                />
              ) : (
                <span style={{
                  ...styles.todoText,
                  textDecoration: todo.completed ? 'line-through' : 'none'
                }}>
                  {todo.text}
                </span>
              )}
            </div>
            <div style={styles.todoActions}>
              {isEditing === todo.id ? (
                <button 
                  onClick={() => handleSaveEdit(todo.id)}
                  style={styles.saveButton}
                >
                  Save
                </button>
              ) : (
                <button 
                  onClick={() => handleEdit(todo)}
                  style={styles.editButton}
                >
                  Edit
                </button>
              )}
              <button 
                onClick={() => handleDelete(todo.id)}
                style={styles.deleteButton}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  inputContainer: {
    display: 'flex',
    gap: '0.5rem',
    marginBottom: '1rem',
  },
  input: {
    flex: 1,
    padding: '0.75rem',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
  },
  addButton: {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  todoList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  todoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.75rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    border: '1px solid #eee',
  },
  todoContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    flex: 1,
  },
  checkbox: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
  },
  todoText: {
    fontSize: '1rem',
    color: '#333',
  },
  todoActions: {
    display: 'flex',
    gap: '0.5rem',
  },
  editButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  deleteButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#dc3545',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  saveButton: {
    padding: '0.5rem 1rem',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  editInput: {
    padding: '0.5rem',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '1rem',
    flex: 1,
  },
};

export default TodoList; 
import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import { useSearchParams, useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import RegistrationModal from '../components/RegistrationModal';
import PlanningModal from '../components/PlanningModal';

const CourseSearch = () => {
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filters, setFilters] = useState({
    subject: '',
    course: '',
    instructionMethod: '',
    location: {
      zipCode: '',
      radius: '25'
    },
    provider: '',
    grade: ''
  });
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    subjects: true,
    dualEnrollment: true,
    grades: true
  });
  const [planningCourse, setPlanningCourse] = useState(null);

  // Filter options
  const instructionMethods = ['Online', 'In Person', 'Hybrid'];
  const providers = ['Sierra College', 'Community College', 'Co-Op', 'Private Institution'];
  const radiusOptions = ['10', '25', '50', '100'];

  // Subject to Course mapping
  const subjectToCourses = {
    'Mathematics': ['Algebra 1', 'Geometry', 'Algebra 2', 'Precalculus', 'AP Calculus'],
    'English': ['English 9', 'English 10', 'English 11', 'AP Literature'],
    // Add other subjects as needed
  };

  useEffect(() => {
    const loadFilteredCourses = async () => {
      try {
        const response = await fetch('/data/sierra-college-courses.json');
        const allCourses = await response.json();
        
        // Apply filters
        let filteredCourses = allCourses;
        
        // Special handling for Math subject
        if (filters.subject === 'Math') {
          filteredCourses = filteredCourses.filter(c => 
            c.courseTitle?.toLowerCase().includes('math') ||
            c.subject?.toLowerCase().includes('math') ||
            c.subjectTags?.some(tag => tag.toLowerCase().includes('math'))
          );
        } else if (filters.subject) {
          filteredCourses = filteredCourses.filter(c => c.subjectTags?.includes(filters.subject));
        }

        if (filters.course) {
          filteredCourses = filteredCourses.filter(c => c.courseTitle.includes(filters.course));
        }
        if (filters.instructionMethod) {
          filteredCourses = filteredCourses.filter(c => c.instructionMethod.includes(filters.instructionMethod));
        }
        if (filters.provider) {
          filteredCourses = filteredCourses.filter(c => c.institution.includes(filters.provider));
        }

        setCourses(filteredCourses);
        setFilteredCourses(filteredCourses);
      } catch (error) {
        console.error('Error loading courses:', error);
      }
    };

    loadFilteredCourses();
  }, [filters, searchQuery]);

  useEffect(() => {
    if (location.state?.subject === 'Math' && location.state?.searchQuery) {
      setSearchQuery(location.state.searchQuery);
      setFilters(prev => ({
        ...prev,
        subject: 'Math',
        course: location.state.searchQuery
      }));
      setShowSearchResults(true);
    }
  }, [location.state]);

  // Filter courses based on all criteria
  const filterCourses = () => {
    return courses.filter(course => {
      // Basic filters
      const matchesSubject = !filters.subject || 
        course.subject === filters.subject || 
        course.subjectTags?.includes(filters.subject) ||
        // Special handling for Math subject
        (filters.subject === 'Math' && (
          course.courseTitle?.toLowerCase().includes('math') ||
          course.subject?.toLowerCase().includes('math') ||
          course.subjectTags?.some(tag => tag.toLowerCase().includes('math'))
        ));

      const matchesProvider = !filters.provider || 
        course.provider === filters.provider || 
        course.institution?.toLowerCase().includes(filters.provider.toLowerCase());

      const matchesCourse = !filters.course || course.courseTitle.includes(filters.course);
      const matchesMethod = !filters.instructionMethod || course.instructionMethod.includes(filters.instructionMethod);
      const matchesLocation = !filters.location.zipCode || course.location.includes(filters.location.zipCode);

      // Enhanced search functionality for the search bar
      const searchMatches = !searchQuery || [
        course.courseTitle,
        course.institution,
        course.courseCode,
        course.subject,
        course.location
      ].some(field => 
        field && field.toLowerCase().includes(searchQuery.toLowerCase())
      );

      const matchesGrade = !filters.grade || 
        course.grade === filters.grade || 
        course.gradeLevel?.includes(filters.grade);

      return matchesSubject && matchesCourse && matchesMethod && 
             matchesProvider && matchesLocation && searchMatches && matchesGrade;
    });
  };

  // Add this helper function to group courses
  const groupCoursesByTitle = (courses) => {
    return courses.reduce((acc, course) => {
      const fullTitle = `${course.courseTitle} (${[course.institution, course.location].filter(Boolean).join(' - ')})`;
      if (!acc[fullTitle]) {
        acc[fullTitle] = [];
      }
      acc[fullTitle].push(course);
      return acc;
    }, {});
  };

  // Replace the existing renderCourseCard with this new version
  const renderCourseCard = (courseGroup) => {
    const [title, courses] = courseGroup;
    
    return (
      <div key={title} style={styles.courseCard}>
        {/* Course Header Section */}
        <div style={styles.courseHeader}>
          <h3 style={styles.courseTitle}>{title}</h3>
        </div>

        {/* Prerequisites (if they exist) */}
        {courses[0].prerequisites && (
          <p style={styles.prerequisites}>
            <strong>Prerequisites:</strong> {courses[0].prerequisites}
          </p>
        )}

        {/* Course Sections */}
        <div style={styles.sectionsList}>
          <h4 style={styles.sectionsHeader}>Available Sections:</h4>
          {courses.map((section, index) => (
            <div key={`${section.id}-${index}`} style={styles.sectionItem}>
              <div style={styles.sectionInfo}>
                <div style={styles.sectionDetails}>
                  <span><strong>Section Code:</strong> {section.courseCode}</span>
                  <span><strong>Schedule:</strong> {section.courseSchedule}</span>
                  <span><strong>Dates:</strong> {section.courseDates}</span>
                  <span><strong>Instructor:</strong> {section.instructor}</span>
                  <span><strong>Format:</strong> {section.instructionMethod}</span>
                  <span><strong>Credits:</strong> {section.credits}</span>
                </div>
                <div style={styles.buttonContainer}>
                  <button
                    onClick={() => handlePlanClick(section)}
                    style={{...styles.enrollButton, backgroundColor: '#007bff'}}
                  >
                    Plan
                  </button>
                  <button
                    onClick={() => handleRegisterClick(section)}
                    style={styles.enrollButton}
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleRegisterClick = (course) => {
    setSelectedCourse(course);
    setIsRegistrationModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCourse(null);
    setIsRegistrationModalOpen(false);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowSearchResults(query.length > 0);
    
    // Optional: Clear other filters when searching
    if (query.length > 0) {
      setFilters({
        subject: '',
        course: '',
        instructionMethod: '',
        location: { zipCode: '', radius: '25' },
        provider: '',
        grade: ''
      });
    }
  };

  const renderDefaultCategories = () => (
    <div style={styles.categoriesContainer}>
      {/* By Subject Section */}
      <div style={styles.categorySection}>
        <button 
          onClick={() => setExpandedSections({
            ...expandedSections,
            subjects: !expandedSections.subjects
          })}
          style={styles.sectionHeader}
        >
          <h2 style={styles.categoryTitle}>By Subject</h2>
          <span style={styles.expandIcon}>
            {expandedSections.subjects ? '−' : '+'}
          </span>
        </button>
        
        {expandedSections.subjects && (
          <div style={styles.categoryGrid}>
            {[
              'Math', 'Science', 'English', 'History', 
              'Foreign Language', 'Art', 'Music', 
              'Physical Education', 'Computer Science', 'Other'
            ].map(subject => (
              <button 
                key={subject}
                onClick={() => {
                  setShowSearchResults(true);
                  if (subject === 'Math') {
                    setFilters({ 
                      ...filters, 
                      subject: 'Math',
                      course: '',
                      instructionMethod: '',
                      location: { zipCode: '', radius: '25' },
                      provider: '',
                      grade: ''
                    });
                    // Don't set searchQuery for Math
                  } else {
                    setSearchQuery('');
                    setFilters({ 
                      ...filters, 
                      subject: subject,
                      course: '',
                      instructionMethod: '',
                      location: { zipCode: '', radius: '25' },
                      provider: '',
                      grade: ''
                    });
                  }
                }}
                style={styles.categoryButton}
              >
                {subject}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* New By Grade Section */}
      <div style={styles.categorySection}>
        <button 
          onClick={() => setExpandedSections({
            ...expandedSections,
            grades: !expandedSections.grades
          })}
          style={styles.sectionHeader}
        >
          <h2 style={styles.categoryTitle}>By Grade</h2>
          <span style={styles.expandIcon}>
            {expandedSections.grades ? '−' : '+'}
          </span>
        </button>
        
        {expandedSections.grades && (
          <div style={styles.categoryGrid}>
            {[
              'Preschool',
              'Kindergarten',
              '1st grade',
              '2nd grade',
              '3rd grade',
              '4th grade',
              '5th grade',
              '6th grade',
              '7th grade',
              '8th grade',
              '9th grade',
              '10th grade',
              '11th grade',
              '12th grade'
            ].map(grade => (
              <button 
                key={grade}
                onClick={() => {
                  setSearchQuery(''); // Clear the search query
                  setShowSearchResults(true);
                  setFilters({ 
                    ...filters, 
                    grade: grade, // Add grade to filters
                    subject: '', // Clear other filters
                    course: '',
                    instructionMethod: '',
                    location: { zipCode: '', radius: '25' },
                    provider: ''
                  });
                }}
                style={styles.categoryButton}
              >
                {grade}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Dual Enrollment Section */}
      <div style={styles.categorySection}>
        <button 
          onClick={() => setExpandedSections({
            ...expandedSections,
            dualEnrollment: !expandedSections.dualEnrollment
          })}
          style={styles.sectionHeader}
        >
          <h2 style={styles.categoryTitle}>Dual Enrollment</h2>
          <span style={styles.expandIcon}>
            {expandedSections.dualEnrollment ? '−' : '+'}
          </span>
        </button>
        
        {expandedSections.dualEnrollment && (
          <div style={styles.categoryGrid}>
            {[
              'Sierra College',
              'Community College',
              'College/University'
            ].map(type => (
              <button 
                key={type}
                onClick={() => {
                  setSearchQuery(''); // Clear the search query
                  setShowSearchResults(true);
                  setFilters({ 
                    ...filters, 
                    provider: type,
                    subject: '', // Clear other filters
                    course: '',
                    instructionMethod: '',
                    location: { zipCode: '', radius: '25' }
                  });
                }}
                style={styles.categoryButton}
              >
                {type}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const handlePlanClick = (course) => {
    console.log('Course data received:', course);
    
    // Updated schedule pattern to better handle various formats including &
    const schedulePattern = /(Monday|Tuesday|Wednesday|Thursday|Friday|Mon|Tue|Wed|Thu|Fri)(?:\s*&\s*|\s*,\s*)(Monday|Tuesday|Wednesday|Thursday|Friday|Mon|Tue|Wed|Thu|Fri)?,?\s*(\d{1,2}):(\d{2})(?:am|pm)?\s*-\s*(\d{1,2}):(\d{2})(?:am|pm)?/i;
    const scheduleMatch = course.courseSchedule.match(schedulePattern);
    console.log('Schedule match:', scheduleMatch);
    
    let days = [];
    let startTime = '';
    let endTime = '';
    
    if (scheduleMatch) {
      // Get both days
      days = [scheduleMatch[1]];
      if (scheduleMatch[2]) days.push(scheduleMatch[2]);
      
      // Convert to short day format
      days = days.map(day => {
        const shortDay = day.substring(0, 3);
        return shortDay.charAt(0).toUpperCase() + shortDay.slice(1).toLowerCase();
      });
      
      // Parse times
      const formatTime = (hour, minute) => {
        hour = parseInt(hour);
        minute = parseInt(minute);
        const timeStr = course.courseSchedule.toLowerCase();
        const isPM = timeStr.includes('pm') || (hour < 12 && hour > 7);
        if (isPM && hour < 12) hour += 12;
        return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      };
      
      startTime = formatTime(scheduleMatch[3], scheduleMatch[4]);
      endTime = formatTime(scheduleMatch[5], scheduleMatch[6]);
    }

    const courseObject = {
      id: course.courseCode,
      name: course.courseTitle,
      section: course.section || '01',
      days: days,
      startTime: startTime,
      endTime: endTime,
      instructor: course.instructor,
      courseTitle: course.courseTitle,
      courseSchedule: course.courseSchedule,
      courseDates: course.courseDates,
      color: getRandomColor(),
      selected: true
    };

    setPlanningCourse(courseObject);
  };

  const handlePlanCourse = (planningDetails) => {
    const { course, year, term, subject } = planningDetails;
    
    // Add to planner data
    const savedCourseData = JSON.parse(localStorage.getItem('coursePlanningData') || '{}');
    
    // Initialize the structure if it doesn't exist
    if (!savedCourseData[subject]) {
      savedCourseData[subject] = {
        '9th': [], '10th': [], '11th': [], '12th': []
      };
    }
    
    // Ensure the year array exists and add course if it's not already there
    if (!savedCourseData[subject][year]) {
      savedCourseData[subject][year] = [];
    }
    
    // Check if course already exists
    const courseExists = savedCourseData[subject][year]
      .some(c => c.id === course.id);
    
    if (!courseExists) {
      savedCourseData[subject][year].push(course);
    }
    
    localStorage.setItem('coursePlanningData', JSON.stringify(savedCourseData));

    // Add to schedule data (similar duplicate check)
    const savedScheduleData = JSON.parse(localStorage.getItem('scheduleData') || '{}');
    if (!savedScheduleData[term]) {
      savedScheduleData[term] = [];
    }
    
    const scheduleExists = savedScheduleData[term]
      .some(c => c.id === course.id);
    
    if (!scheduleExists) {
      savedScheduleData[term].push(course);
    }
    
    localStorage.setItem('scheduleData', JSON.stringify(savedScheduleData));
  };

  const getRandomColor = () => {
    const colors = ['#4299E1', '#48BB78', '#ED8936', '#9F7AEA', '#F56565'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div style={styles.container}>
      <PageHeader title="Course Search" />
      
      {/* Search Bar */}
      <div style={styles.searchBarContainer}>
        <input
          type="text"
          placeholder="Search for courses..."
          value={searchQuery}
          onChange={handleSearch}
          style={styles.searchBar}
        />
      </div>

      {/* Main content area */}
      {showSearchResults ? (
        <div style={styles.mainContent}>
          {/* Course Results */}
          <div style={styles.courseResults}>
            <div style={styles.courseGrid}>
              {Object.entries(groupCoursesByTitle(filterCourses())).map(courseGroup => 
                renderCourseCard(courseGroup)
              )}
            </div>
          </div>
          {/* Filters Sidebar */}
          <div style={styles.filterSidebar}>
            <div style={styles.filterSection}>
              <div style={styles.filterGrid}>
                {/* Subject Filter */}
                <div style={styles.filterGroup}>
                  <label style={styles.label}>Subject</label>
                  <select
                    style={styles.select}
                    value={filters.subject}
                    onChange={(e) => setFilters({...filters, subject: e.target.value})}
                  >
                    <option value="">All Subjects</option>
                    {Object.keys(subjectToCourses).map(subject => (
                      <option key={subject} value={subject}>{subject}</option>
                    ))}
                  </select>
                </div>

                {/* Course Filter - Dynamic based on selected subject */}
                <div style={styles.filterGroup}>
                  <label style={styles.label}>Course</label>
                  <select
                    style={styles.select}
                    value={filters.course}
                    onChange={(e) => setFilters({...filters, course: e.target.value})}
                    disabled={!filters.subject}
                  >
                    <option value="">All Courses</option>
                    {filters.subject && subjectToCourses[filters.subject]?.map(course => (
                      <option key={course} value={course}>{course}</option>
                    ))}
                  </select>
                </div>

                {/* Instruction Method Filter */}
                <div style={styles.filterGroup}>
                  <label style={styles.label}>Format</label>
                  <select
                    style={styles.select}
                    value={filters.instructionMethod}
                    onChange={(e) => setFilters({...filters, instructionMethod: e.target.value})}
                  >
                    <option value="">Any Format</option>
                    {instructionMethods.map(method => (
                      <option key={method} value={method}>{method}</option>
                    ))}
                  </select>
                </div>

                {/* Provider Filter */}
                <div style={styles.filterGroup}>
                  <label style={styles.label}>Provider</label>
                  <select
                    style={styles.select}
                    value={filters.provider}
                    onChange={(e) => setFilters({...filters, provider: e.target.value})}
                  >
                    <option value="">Any Provider</option>
                    {providers.map(provider => (
                      <option key={provider} value={provider}>{provider}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Location Filter */}
              <div style={styles.locationFilter}>
                <div style={styles.filterGroup}>
                  <label style={styles.label}>ZIP Code</label>
                  <input
                    type="text"
                    style={styles.input}
                    value={filters.location.zipCode}
                    onChange={(e) => setFilters({
                      ...filters,
                      location: {...filters.location, zipCode: e.target.value}
                    })}
                    placeholder="Enter ZIP code"
                  />
                </div>

                <div style={styles.filterGroup}>
                  <label style={styles.label}>Radius</label>
                  <select
                    style={styles.select}
                    value={filters.location.radius}
                    onChange={(e) => setFilters({
                      ...filters,
                      location: {...filters.location, radius: e.target.value}
                    })}
                  >
                    {radiusOptions.map(radius => (
                      <option key={radius} value={radius}>{radius} miles</option>
                    ))}
                  </select>
                </div>
              </div>

              <button
                onClick={() => setFilters({
                  subject: '',
                  course: '',
                  instructionMethod: '',
                  location: { zipCode: '', radius: '25' },
                  provider: '',
                  grade: ''
                })}
                style={styles.clearFilters}
              >
                Clear Filters
              </button>
            </div>
          </div>
        </div>
      ) : (
        renderDefaultCategories()
      )}

      {isRegistrationModalOpen && (
        <RegistrationModal
          course={selectedCourse}
          onClose={handleCloseModal}
        />
      )}

      {planningCourse && (
        <PlanningModal
          course={planningCourse}
          onClose={() => setPlanningCourse(null)}
          onPlan={handlePlanCourse}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '24px',
    position: 'relative',
  },
  filtersContainer: {
    marginBottom: '2rem',
  },
  searchInput: {
    width: '100%',
    padding: '0.75rem',
    fontSize: '1.1rem',
    borderRadius: '8px',
    border: '1px solid #ddd',
    marginBottom: '1rem',
  },
  filterControls: {
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
  },
  select: {
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    minWidth: '200px',
  },
  courseGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  courseCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '1.5rem',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    width: '100%',
    maxWidth: '100%',
  },
  courseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  courseTitle: {
    margin: 0,
    fontSize: '1.25rem',
    color: '#333',
  },
  courseCode: {
    color: '#666',
    fontSize: '0.9rem',
  },
  courseInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '1rem',
    marginBottom: '1rem',
    fontSize: '0.9rem',
  },
  courseDescription: {
    fontSize: '0.9rem',
    color: '#666',
    marginBottom: '1rem',
    lineHeight: '1.5',
  },
  courseFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    paddingTop: '1rem',
    borderTop: '1px solid #eee',
  },
  enrollment: {
    color: '#666',
    fontSize: '0.9rem',
  },
  enrollButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    fontSize: '0.9rem',
  },
  filterSection: {
    position: 'sticky',
    top: '2rem',
    backgroundColor: 'white',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  filterGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  filterGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  label: {
    fontSize: '0.9rem',
    color: '#666',
    fontWeight: '500',
  },
  select: {
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '0.9rem',
    color: '#333',
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      borderColor: '#007BFF',
    },
  },
  locationFilter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  input: {
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '0.9rem',
    '&:focus': {
      outline: 'none',
      borderColor: '#007BFF',
    },
  },
  clearFilters: {
    backgroundColor: '#6c757d',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '0.75rem 1rem',
    cursor: 'pointer',
    fontSize: '0.9rem',
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: '#5a6268',
    },
  },
  sectionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  sectionItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderBottom: '1px solid #eee',
  },
  sectionFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '1rem',
    paddingTop: '1rem',
    borderTop: '1px solid #eee',
  },
  sectionsHeader: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '1rem',
    borderBottom: '1px solid #eee',
    paddingBottom: '0.5rem',
  },
  sectionInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
  },
  sectionDetails: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '0.75rem',
    fontSize: '0.9rem',
  },
  sectionItem: {
    padding: '1rem',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    marginBottom: '0.5rem',
  },
  searchBarContainer: {
    marginBottom: '24px',
    width: '100%',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  searchBar: {
    width: '100%',
    padding: '12px 16px',
    fontSize: '16px',
    border: '1px solid #E2E8F0',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  mainContent: {
    display: 'flex',
    gap: '24px',
    alignItems: 'flex-start',
    width: '100%',
  },
  courseResults: {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
    width: '100%',
  },
  filterSidebar: {
    width: '280px',
    flexShrink: 0,
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    position: 'sticky',
    top: '24px',
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
    marginTop: '0',
  },
  categoriesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
  },
  categorySection: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1px solid #E2E8F0',
  },
  sectionHeader: {
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  categoryTitle: {
    fontSize: '1.1rem',
    fontWeight: '600',
    color: '#333',
    margin: 0,
  },
  expandIcon: {
    fontSize: '1.5rem',
    color: '#666',
    fontWeight: 'bold',
  },
  categoryGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: '12px',
    padding: '16px',
  },
  categoryButton: {
    padding: '8px 12px',
    backgroundColor: '#f7fafc',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    fontSize: '14px',
    color: '#2d3748',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#edf2f7',
      borderColor: '#cbd5e0',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginLeft: '10px'
  },
  enrollButton: {
    padding: '8px 16px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#4CAF50',
    color: 'white',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      opacity: 0.9
    }
  }
};

export default CourseSearch; 
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';

const MyCourses = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({
    name: '',
    term: '',
    startDate: '',
    endDate: '',
    schedule: {
      days: [],
      startTime: '',
      endTime: '',
      duration: ''
    },
    students: [],
    instructionMethod: '',
    subjectArea: '',
    evaluationMethod: '',
    materials: '',
    isGraded: false,
    description: '',
    lmsLink: ''
  });
  const [expandedTerms, setExpandedTerms] = useState({});
  const [terms, setTerms] = useState([]);
  const [isTermModalOpen, setIsTermModalOpen] = useState(false);
  const [newTerm, setNewTerm] = useState('');

  const subjects = [
    'Mathematics',
    'Science',
    'English',
    'History',
    'Foreign Language',
    'Art',
    'Music',
    'Physical Education',
    'Computer Science',
    'Other'
  ];

  useEffect(() => {
    // Load courses from backend/localStorage
    const loadCourses = async () => {
      const savedCourses = localStorage.getItem('userCourses');
      if (savedCourses) {
        setCourses(JSON.parse(savedCourses));
      }
    };
    loadCourses();
  }, [user?.id]);

  useEffect(() => {
    const savedTerms = localStorage.getItem('userTerms');
    if (savedTerms) {
      setTerms(JSON.parse(savedTerms));
    }
  }, []);

  const handleCourseClick = (courseId) => {
    navigate(`/mycourses/${courseId}`);
  };

  const handleCreateCourse = () => {
    const courseId = Date.now().toString();
    navigate(`/mycourses/${courseId}/edit`);
  };

  const handleSubmitCourse = () => {
    const courseId = Date.now().toString();
    const courseData = {
      id: courseId,
      ...newCourse,
      schedule: newCourse.schedule || {
        days: [],
        startTime: '',
        endTime: '',
        duration: ''
      },
      students: newCourse.students || [],
      progress: 0,
      modules: [],
      assignments: []
    };

    const updatedCourses = [...courses, courseData];
    setCourses(updatedCourses);
    localStorage.setItem('userCourses', JSON.stringify(updatedCourses));

    // Reset form and close modal
    setNewCourse({
      name: '',
      term: '',
      startDate: '',
      endDate: '',
      schedule: {
        days: [],
        startTime: '',
        endTime: '',
        duration: ''
      },
      students: [],
      instructionMethod: '',
      subjectArea: '',
      evaluationMethod: '',
      materials: '',
      isGraded: false,
      description: '',
      lmsLink: ''
    });
    setIsModalOpen(false);
  };

  const handleCourseDelete = (courseId, e) => {
    e.stopPropagation(); // Prevent triggering the course click when clicking delete
    const updatedCourses = courses.filter(course => course.id !== courseId);
    setCourses(updatedCourses);
    localStorage.setItem('userCourses', JSON.stringify(updatedCourses));
  };

  // Group courses by term
  const coursesByTerm = courses.reduce((acc, course) => {
    if (!acc[course.term]) {
      acc[course.term] = [];
    }
    acc[course.term].push(course);
    return acc;
  }, {});

  // Sort terms by date (assuming term format like "Fall 2024")
  const sortedTerms = Object.keys(coursesByTerm).sort((a, b) => {
    // Extract year and season from term names
    const yearA = a.match(/\d{4}/)?.[0] || '0';
    const yearB = b.match(/\d{4}/)?.[0] || '0';
    
    if (yearA !== yearB) {
      return parseInt(yearB) - parseInt(yearA);
    }
    
    // If same year, sort by season
    const seasons = ['Spring', 'Summer', 'Fall', 'Winter'];
    const seasonA = seasons.findIndex(s => a.includes(s));
    const seasonB = seasons.findIndex(s => b.includes(s));
    
    return seasonB - seasonA;
  });

  const toggleTerm = (term) => {
    setExpandedTerms(prev => ({
      ...prev,
      [term]: !prev[term]
    }));
  };

  const handleCreateTerm = () => {
    if (!newTerm.trim()) {
      alert('Please enter a term name');
      return;
    }
    
    const termData = {
      id: Date.now().toString(),
      name: newTerm.trim(),
      order: terms.length // For sorting
    };
    
    const updatedTerms = [...terms, termData];
    setTerms(updatedTerms);
    localStorage.setItem('userTerms', JSON.stringify(updatedTerms));
    setNewTerm('');
    setIsTermModalOpen(false);
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.mainContent}>
        <div style={styles.header}>
          <h1 style={styles.title}>My Courses</h1>
          <div style={styles.headerActions}>
            <button 
              style={styles.createButton}
              onClick={() => setIsTermModalOpen(true)}
            >
              + Create New Term
            </button>
            <button 
              style={styles.createButton}
              onClick={() => setIsModalOpen(true)}
            >
              + Create New Course
            </button>
          </div>
        </div>

        <div style={styles.termsList}>
          {sortedTerms.map((term) => (
            <div key={term} style={styles.termSection}>
              <div 
                style={styles.termHeader}
                onClick={() => toggleTerm(term)}
              >
                <h2 style={styles.termTitle}>{term}</h2>
                <span style={styles.termArrow}>
                  {expandedTerms[term] ? '▼' : '▶'}
                </span>
              </div>
              
              {expandedTerms[term] && (
                <div style={styles.courseGrid}>
                  {coursesByTerm[term].map((course) => (
                    <div 
                      key={course.id}
                      style={styles.courseCard}
                      onClick={() => handleCourseClick(course.id)}
                    >
                      <div style={styles.courseHeader} className={course.subjectArea?.toLowerCase()}>
                        <h3 style={styles.courseName}>{course.name}</h3>
                        <button
                          onClick={(e) => handleCourseDelete(course.id, e)}
                          style={styles.deleteButton}
                          aria-label="Delete course"
                        >
                          ×
                        </button>
                      </div>
                      <div style={styles.courseInfo}>
                        <p style={styles.courseTerm}>Term: {course.term}</p>
                        <p style={styles.courseSubject}>Subject: {course.subjectArea}</p>
                        {course.status && (
                          <div style={styles.statusContainer}>
                            <p style={styles.status}>{course.status}</p>
                            <p style={styles.submissionDate}>
                              Submitted: {new Date(course.submittedDate).toLocaleDateString()}
                            </p>
                            {course.students?.length > 0 && (
                              <p style={styles.student}>Student: {course.students[0]}</p>
                            )}
                          </div>
                        )}
                        <div style={styles.courseProgress}>
                          <div style={styles.progressBar}>
                            <div 
                              style={{
                                ...styles.progressFill,
                                width: `${course.progress || 0}%`
                              }}
                            />
                          </div>
                          <span style={styles.progressText}>{course.progress || 0}% Complete</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        {isModalOpen && (
          <div style={styles.modalOverlay}>
            <div style={styles.modal}>
              <h2 style={styles.modalTitle}>Create New Course</h2>
              
              {/* Basic Info */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Course Name*</label>
                <input
                  type="text"
                  style={styles.input}
                  value={newCourse.name}
                  onChange={(e) => setNewCourse({...newCourse, name: e.target.value})}
                  required
                />
              </div>

              {/* Term Selection */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Term*</label>
                <select
                  style={styles.input}
                  value={newCourse.term}
                  onChange={(e) => setNewCourse({...newCourse, term: e.target.value})}
                  required
                >
                  <option value="">Select a term</option>
                  {terms.map(term => (
                    <option key={term.id} value={term.name}>{term.name}</option>
                  ))}
                </select>
              </div>

              {/* Course Description */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Course Description</label>
                <textarea
                  style={{...styles.input, minHeight: '100px'}}
                  value={newCourse.description}
                  onChange={(e) => setNewCourse({...newCourse, description: e.target.value})}
                  placeholder="Enter course description..."
                />
              </div>

              {/* Subject Area */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Subject Area*</label>
                <select
                  style={styles.input}
                  value={newCourse.subjectArea}
                  onChange={(e) => setNewCourse({...newCourse, subjectArea: e.target.value})}
                  required
                >
                  <option value="">Select subject area</option>
                  {subjects.map(subject => (
                    <option key={subject} value={subject}>{subject}</option>
                  ))}
                </select>
              </div>

              {/* Dates */}
              <div style={styles.dateContainer}>
                <div style={styles.formGroup}>
                  <label style={styles.label}>Start Date*</label>
                  <input
                    type="date"
                    style={styles.input}
                    value={newCourse.startDate}
                    onChange={(e) => setNewCourse({...newCourse, startDate: e.target.value})}
                    required
                  />
                </div>
                <div style={styles.formGroup}>
                  <label style={styles.label}>End Date*</label>
                  <input
                    type="date"
                    style={styles.input}
                    value={newCourse.endDate}
                    onChange={(e) => setNewCourse({...newCourse, endDate: e.target.value})}
                    required
                  />
                </div>
              </div>

              {/* Schedule */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Schedule</label>
                <div style={styles.scheduleContainer}>
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                    <label key={day} style={styles.dayCheckbox}>
                      <input
                        type="checkbox"
                        checked={newCourse.schedule.days.includes(day)}
                        onChange={(e) => {
                          const days = e.target.checked 
                            ? [...newCourse.schedule.days, day]
                            : newCourse.schedule.days.filter(d => d !== day);
                          setNewCourse({
                            ...newCourse,
                            schedule: {...newCourse.schedule, days}
                          });
                        }}
                      />
                      {day}
                    </label>
                  ))}
                </div>
                <div style={styles.timeContainer}>
                  <input
                    type="time"
                    style={styles.timeInput}
                    value={newCourse.schedule.startTime}
                    onChange={(e) => setNewCourse({
                      ...newCourse,
                      schedule: {...newCourse.schedule, startTime: e.target.value}
                    })}
                  />
                  <span>to</span>
                  <input
                    type="time"
                    style={styles.timeInput}
                    value={newCourse.schedule.endTime}
                    onChange={(e) => setNewCourse({
                      ...newCourse,
                      schedule: {...newCourse.schedule, endTime: e.target.value}
                    })}
                  />
                </div>
                <input
                  type="text"
                  style={styles.input}
                  placeholder="Duration (e.g., 50 minutes)"
                  value={newCourse.schedule.duration}
                  onChange={(e) => setNewCourse({
                    ...newCourse,
                    schedule: {...newCourse.schedule, duration: e.target.value}
                  })}
                />
              </div>

              {/* Students */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Students</label>
                <input
                  type="text"
                  style={styles.input}
                  placeholder="Enter student names (comma-separated)"
                  value={newCourse.students.join(', ')}
                  onChange={(e) => setNewCourse({
                    ...newCourse,
                    students: e.target.value.split(',').map(s => s.trim()).filter(Boolean)
                  })}
                />
              </div>

              {/* Instruction Method */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Instruction Method</label>
                <select
                  style={styles.input}
                  value={newCourse.instructionMethod}
                  onChange={(e) => setNewCourse({...newCourse, instructionMethod: e.target.value})}
                >
                  <option value="">Select method</option>
                  <option value="In-Person">In-Person</option>
                  <option value="Online">Online</option>
                  <option value="Hybrid">Hybrid</option>
                </select>
              </div>

              {/* Evaluation Method */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Evaluation Method</label>
                <input
                  type="text"
                  style={styles.input}
                  value={newCourse.evaluationMethod}
                  onChange={(e) => setNewCourse({...newCourse, evaluationMethod: e.target.value})}
                  placeholder="How will students be evaluated?"
                />
              </div>

              {/* Materials */}
              <div style={styles.formGroup}>
                <label style={styles.label}>Textbook/Materials</label>
                <textarea
                  style={{...styles.input, minHeight: '60px'}}
                  value={newCourse.materials}
                  onChange={(e) => setNewCourse({...newCourse, materials: e.target.value})}
                  placeholder="List required materials..."
                />
              </div>

              {/* Graded */}
              <div style={styles.formGroup}>
                <label style={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={newCourse.isGraded}
                    onChange={(e) => setNewCourse({...newCourse, isGraded: e.target.checked})}
                  />
                  Graded Activity
                </label>
              </div>

              {/* LMS Link */}
              <div style={styles.formGroup}>
                <label style={styles.label}>LMS Link</label>
                <input
                  type="url"
                  style={styles.input}
                  value={newCourse.lmsLink}
                  onChange={(e) => setNewCourse({...newCourse, lmsLink: e.target.value})}
                  placeholder="https://..."
                />
              </div>

              {/* Buttons */}
              <div style={styles.modalButtons}>
                <button 
                  style={{...styles.button, backgroundColor: '#6c757d'}}
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </button>
                <button 
                  style={{...styles.button, backgroundColor: '#4CAF50'}}
                  onClick={handleSubmitCourse}
                >
                  Create Course
                </button>
              </div>
            </div>
          </div>
        )}

        {isTermModalOpen && (
          <div style={styles.modalOverlay}>
            <div style={styles.modal}>
              <h2 style={styles.modalTitle}>Create New Term</h2>
              <div style={styles.formGroup}>
                <label style={styles.label}>Term Name*</label>
                <input
                  type="text"
                  value={newTerm}
                  onChange={(e) => setNewTerm(e.target.value)}
                  style={styles.input}
                  placeholder="e.g., Fall 2024"
                  required
                />
              </div>
              <div style={styles.modalButtons}>
                <button 
                  style={{...styles.button, backgroundColor: '#6c757d'}}
                  onClick={() => setIsTermModalOpen(false)}
                >
                  Cancel
                </button>
                <button 
                  style={{...styles.button, backgroundColor: '#4CAF50'}}
                  onClick={handleCreateTerm}
                >
                  Create Term
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  pageContainer: {
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    paddingLeft: '0px',
  },
  mainContent: {
    padding: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    padding: '0',
  },
  title: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#2D3748',
    margin: 0,
  },
  headerActions: {
    display: 'flex',
    gap: '12px',
  },
  createButton: {
    padding: '8px 16px',
    backgroundColor: '#00356B',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
  termsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  termSection: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    width: '100%',
  },
  courseGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gap: '20px',
    padding: '20px',
  },
  courseCard: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    border: '1px solid #e2e8f0',
    overflow: 'hidden',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  },
  courseHeader: {
    position: 'relative',
    padding: '1.5rem',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #eee',
  },
  courseName: {
    margin: 0,
    fontSize: '1.25rem',
    color: '#333',
  },
  courseInfo: {
    padding: '1rem',
  },
  courseTeacher: {
    margin: '0 0 0.5rem 0',
    color: '#666',
  },
  courseTerm: {
    margin: '0 0 1rem 0',
    color: '#666',
  },
  courseProgress: {
    marginTop: '1rem',
  },
  progressBar: {
    height: '8px',
    backgroundColor: '#eee',
    borderRadius: '4px',
    overflow: 'hidden',
  },
  progressFill: {
    height: '100%',
    backgroundColor: '#4CAF50',
    transition: 'width 0.3s',
  },
  progressText: {
    fontSize: '0.875rem',
    color: '#666',
    marginTop: '0.25rem',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '2rem',
    width: '90%',
    maxWidth: '500px',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  modalTitle: {
    fontSize: '1.5rem',
    marginBottom: '1.5rem',
    color: '#333',
  },
  formGroup: {
    marginBottom: '1rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    color: '#666',
  },
  input: {
    width: '100%',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '1rem',
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '1.5rem',
  },
  button: {
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    color: 'white',
  },
  deleteButton: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '24px',
    height: '24px',
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    border: 'none',
    color: 'white',
    fontSize: '18px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    lineHeight: '1',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  termHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 1.5rem',
    backgroundColor: '#f8f9fa',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#e9ecef',
    },
  },
  termTitle: {
    margin: 0,
    fontSize: '1.25rem',
    color: '#333',
  },
  termArrow: {
    fontSize: '1rem',
    color: '#666',
    transition: 'transform 0.2s ease',
  },
  dateContainer: {
    display: 'flex',
    gap: '1rem',
  },
  scheduleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  dayCheckbox: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginTop: '0.5rem',
  },
  timeInput: {
    padding: '0.5rem',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '0.875rem',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  statusContainer: {
    backgroundColor: '#f8f9fa',
    padding: '0.75rem',
    borderRadius: '4px',
    marginTop: '0.5rem',
  },
  status: {
    color: '#666',
    fontWeight: '500',
    marginBottom: '0.25rem',
  },
  submissionDate: {
    color: '#888',
    fontSize: '0.875rem',
    marginBottom: '0.25rem',
  },
  student: {
    color: '#666',
    fontSize: '0.875rem',
  },
};

export default MyCourses; 
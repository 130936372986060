import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; // Ensure this path to your logo is correct

const LoginSelection = () => {
  const navigate = useNavigate();

  const handleHighSchoolSelection = () => {
    localStorage.setItem('userType', 'highschool'); // Set userType to 'highschool'
    navigate('/login'); // Navigate to the login page
  };

  const handleSelection = () => {
    localStorage.setItem('userType', 'college'); // Still set userType to 'parent'
    navigate('/login'); // Navigates to the login page (same as the 'student' would)
  };

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.logoContainer} onClick={handleHighSchoolSelection}>
          <img src={logo} alt="Logo" style={styles.logo} />
          <h1 style={styles.companyName}>YourEDU</h1>
        </div>
        <h2 style={styles.header}>Log In...</h2>
        <div style={styles.buttonGroup}>
          <div style={styles.buttonWrapper}>
            <button style={styles.activeButton} onClick={handleSelection}>
              Parent 
            </button>
          </div>
          <div style={styles.buttonWrapper}>
            <button style={styles.disabledButton} disabled>
              Guidance Counselor
            </button>
            <p style={styles.comingSoonText}>Coming soon!</p>
          </div>
          <div style={styles.buttonWrapper}>
            <button style={styles.disabledButton} disabled>
              Student
            </button>
            <p style={styles.comingSoonText}>Coming soon!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#00356b',
    position: 'fixed',
    top: 0,
    left: 0,
    margin: 0,
  },
  
  container: {
    backgroundColor: '#fff',
    borderRadius: '12px',
    padding: '40px 50px',
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.12)',
    textAlign: 'center',
    width: '420px',
  },
  
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
    cursor: 'pointer',
  },
  
  logo: {
    height: '45px',
    marginRight: '12px',
  },
  
  companyName: {
    fontSize: '24px',
    fontWeight: '600',
    color: '#1a365d',
    fontFamily: "'Inter', sans-serif",
    margin: 0,
  },
  
  header: {
    fontSize: '28px',
    marginBottom: '30px',
    color: '#1a365d',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '600',
  },
  
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column', // Changed to column for better spacing
    gap: '20px',
    alignItems: 'center',
  },
  
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  
  activeButton: {
    width: '100%',
    height: '50px',
    fontSize: '16px',
    backgroundColor: '#1a365d',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#2c5282',
    },
  },
  
  disabledButton: {
    width: '100%',
    height: '50px',
    fontSize: '16px',
    backgroundColor: '#E2E8F0',
    color: '#A0AEC0',
    border: 'none',
    borderRadius: '8px',
    cursor: 'not-allowed',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
  },
  
  comingSoonText: {
    marginTop: '8px',
    fontSize: '14px',
    color: '#718096',
    fontFamily: "'Inter', sans-serif",
    fontWeight: '500',
  },
};

// Add font import
const fontImport = document.createElement('link');
fontImport.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
fontImport.rel = 'stylesheet';
document.head.appendChild(fontImport);

export default LoginSelection;

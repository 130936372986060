import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import PageHeader from '../components/PageHeader';

const Compliance = () => {
  const { user } = useAuth();
  const [selectedState, setSelectedState] = useState('');
  
  // Using the same states array as in Onboarding.js
  const states = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "District of Columbia", "Florida", "Georgia", "Hawaii",
    "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
    "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
    "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma",
    "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
    "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
    "West Virginia", "Wisconsin", "Wyoming"
  ];

  const stateRequirements = {
    'California': {
      process: 'File a Private School Affidavit (PSA) annually',
      deadlines: 'October 1-15 annually',
      requirements: [
        'File Private School Affidavit between October 1-15',
        'Maintain attendance records',
        'Provide instruction in required subjects',
        'Teachers must be "capable of teaching"'
      ],
      resources: [
        'California Department of Education website',
        'California Homeschool Network',
        'HomeSchool Association of California'
      ]
    },
    // Add more states here
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  return (
    <div style={styles.container}>
      <PageHeader title="Homeschool Compliance" />
      
      <div style={styles.content}>
        <div style={styles.stateSelector}>
          <label style={styles.label}>
            Select Your State:
            <select 
              value={selectedState} 
              onChange={handleStateChange}
              style={styles.select}
            >
              <option value="">Choose a state...</option>
              {states.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>
          </label>
        </div>

        {selectedState && stateRequirements[selectedState] ? (
          <div style={styles.requirementsContainer}>
            <h2 style={styles.sectionTitle}>
              Homeschool Requirements for {selectedState}
            </h2>
            
            <div style={styles.infoSection}>
              <h3 style={styles.subTitle}>Process</h3>
              <p>{stateRequirements[selectedState].process}</p>
            </div>

            <div style={styles.infoSection}>
              <h3 style={styles.subTitle}>Key Deadlines</h3>
              <p>{stateRequirements[selectedState].deadlines}</p>
            </div>

            <div style={styles.infoSection}>
              <h3 style={styles.subTitle}>Requirements</h3>
              <ul style={styles.list}>
                {stateRequirements[selectedState].requirements.map((req, index) => (
                  <li key={index} style={styles.listItem}>{req}</li>
                ))}
              </ul>
            </div>

            <div style={styles.infoSection}>
              <h3 style={styles.subTitle}>Helpful Resources</h3>
              <ul style={styles.list}>
                {stateRequirements[selectedState].resources.map((resource, index) => (
                  <li key={index} style={styles.listItem}>{resource}</li>
                ))}
              </ul>
            </div>
          </div>
        ) : selectedState ? (
          <div style={styles.noDataMessage}>
            State information coming soon. Please check back later.
          </div>
        ) : null}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
  },
  content: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  },
  stateSelector: {
    marginBottom: '24px',
  },
  label: {
    display: 'block',
    marginBottom: '8px',
    fontWeight: '500',
    color: '#2d3748',
  },
  select: {
    width: '100%',
    maxWidth: '300px',
    padding: '8px 12px',
    borderRadius: '6px',
    border: '1px solid #e2e8f0',
    fontSize: '16px',
    marginTop: '8px',
  },
  requirementsContainer: {
    marginTop: '24px',
  },
  sectionTitle: {
    color: '#00356b',
    fontSize: '24px',
    marginBottom: '20px',
  },
  infoSection: {
    marginBottom: '24px',
  },
  subTitle: {
    color: '#2d3748',
    fontSize: '18px',
    marginBottom: '12px',
  },
  list: {
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    padding: '8px 0',
    borderBottom: '1px solid #f0f0f0',
  },
  noDataMessage: {
    padding: '20px',
    textAlign: 'center',
    color: '#718096',
    fontSize: '16px',
  }
};

export default Compliance; 
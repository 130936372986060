import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';

const RegistrationModal = ({ course, onClose }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    const savedTerms = localStorage.getItem('userTerms');
    if (savedTerms) {
      setTerms(JSON.parse(savedTerms));
    }
  }, []);

  const [formData, setFormData] = useState({
    studentName: user?.name || '',
    email: user?.email || '',
    phone: '',
    emergencyContact: '',
    accommodations: '',
    term: '',
    agreed: false
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Create registration record
    const registrationData = {
      id: Date.now().toString(),
      courseId: course.id,
      courseTitle: course.courseTitle,
      studentId: user.id,
      status: 'pending',
      submittedAt: new Date().toISOString(),
      ...formData
    };

    // Save registration
    const existingRegistrations = JSON.parse(localStorage.getItem('courseRegistrations') || '[]');
    localStorage.setItem('courseRegistrations', JSON.stringify([...existingRegistrations, registrationData]));

    // Create course in MyCourses
    const newCourse = {
      id: Date.now().toString(),
      name: course.courseTitle,
      term: formData.term,
      subjectArea: course.subject || '',
      description: course.description || '',
      instructionMethod: course.instructionMethod || '',
      startDate: course.courseDates?.split(' - ')[0] || '',
      endDate: course.courseDates?.split(' - ')[1] || '',
      students: [formData.studentName],
      status: 'Registration Pending',
      submittedDate: new Date().toISOString(),
      progress: 0
    };

    // Save to MyCourses
    const existingCourses = JSON.parse(localStorage.getItem('userCourses') || '[]');
    localStorage.setItem('userCourses', JSON.stringify([...existingCourses, newCourse]));

    navigate(`/registration/status`);
    onClose();
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modal}>
        <h2 style={styles.title}>Course Registration</h2>
        <div style={styles.courseInfo}>
          <h3>{course.courseTitle}</h3>
          <p>{course.courseCode} - {course.instructor}</p>
          <p>{course.courseDates}</p>
        </div>

        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Full Name</label>
            <input
              type="text"
              style={styles.input}
              value={formData.studentName}
              onChange={(e) => setFormData({...formData, studentName: e.target.value})}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Email</label>
            <input
              type="email"
              style={styles.input}
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Phone Number</label>
            <input
              type="tel"
              style={styles.input}
              value={formData.phone}
              onChange={(e) => setFormData({...formData, phone: e.target.value})}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Emergency Contact</label>
            <input
              type="text"
              style={styles.input}
              value={formData.emergencyContact}
              onChange={(e) => setFormData({...formData, emergencyContact: e.target.value})}
              required
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Accommodations Needed</label>
            <textarea
              style={{...styles.input, height: '80px'}}
              value={formData.accommodations}
              onChange={(e) => setFormData({...formData, accommodations: e.target.value})}
              placeholder="Optional - List any accommodations you need"
            />
          </div>

          <div style={styles.formGroup}>
            <label style={styles.label}>Term*</label>
            <select
              style={styles.input}
              value={formData.term}
              onChange={(e) => setFormData({...formData, term: e.target.value})}
              required
            >
              <option value="">Select a term</option>
              {terms.map(term => (
                <option key={term.id} value={term.name}>{term.name}</option>
              ))}
            </select>
          </div>

          <div style={styles.checkboxGroup}>
            <input
              type="checkbox"
              id="agreement"
              checked={formData.agreed}
              onChange={(e) => setFormData({...formData, agreed: e.target.checked})}
              required
            />
            <label htmlFor="agreement">
              I agree to the terms and conditions of enrollment
            </label>
          </div>

          <div style={styles.buttonGroup}>
            <button type="button" onClick={onClose} style={styles.cancelButton}>
              Cancel
            </button>
            <button type="submit" style={styles.submitButton}>
              Submit Registration
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '2rem',
    width: '90%',
    maxWidth: '600px',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  title: {
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: '1.5rem',
  },
  courseInfo: {
    backgroundColor: '#f8f9fa',
    padding: '1rem',
    borderRadius: '4px',
    marginBottom: '1.5rem',
  },
  formGroup: {
    marginBottom: '1rem',
  },
  label: {
    display: 'block',
    marginBottom: '0.5rem',
    color: '#666',
  },
  input: {
    width: '100%',
    padding: '0.75rem',
    borderRadius: '4px',
    border: '1px solid #ddd',
    fontSize: '1rem',
  },
  checkboxGroup: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    marginBottom: '1.5rem',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
  cancelButton: {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#6c757d',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  submitButton: {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
  }
};

export default RegistrationModal; 
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

const MathCourseDetail = () => {
  const { courseName } = useParams();
  const navigate = useNavigate();

  const courseDetails = {
    'algebra-1': {
      title: 'Algebra 1',
      description: 'Fundamental concepts of algebra including linear equations, systems of equations, inequalities, functions, polynomials, and rational expressions.',
      typicalGrade: '8th or 9th grade',
      prerequisites: 'Pre-Algebra or equivalent',
      nextCourses: ['Geometry', 'Geometry H'],
      materials: {
        'Common Textbooks': 'Big Ideas Math Algebra 1, CPM Core Connections Algebra',
      },
      tracks: {
        minimum: 'Required for high school graduation',
        standard: 'Foundation for UC/CSU A-G requirements',
        accelerated: 'Starting point for standard math pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0012',
          format: 'In-person/Online'
        }
      ]
    },
    'geometry': {
      title: 'Geometry',
      description: 'A comprehensive study of geometric concepts including lines, angles, triangles, polygons, circles, and solid figures.',
      typicalGrade: '9th or 10th grade',
      prerequisites: 'Algebra 1',
      nextCourses: ['Algebra 2', 'Algebra 2/Trig H'],
      materials: {
        'Common Textbooks': 'Big Ideas Math Geometry, CPM Geometry Connections'
      },
      tracks: {
        minimum: 'Required for high school graduation',
        standard: 'Meets UC/CSU A-G requirements',
        accelerated: 'Standard college-prep pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0031',
          format: 'In-person/Online'
        }
      ]
    },
    'algebra-2': {
      title: 'Algebra 2',
      description: 'Advanced algebraic concepts including complex numbers, polynomial functions, exponential and logarithmic functions, and conic sections.',
      typicalGrade: '10th or 11th grade',
      prerequisites: 'Geometry',
      nextCourses: ['Precalculus', 'Statistics'],
      materials: {
        'Common Textbooks': 'Big Ideas Math Algebra 2, CPM Core Connections Algebra 2'
      },
      tracks: {
        minimum: 'Completes minimum graduation requirements',
        standard: 'Completes UC/CSU A-G requirements',
        accelerated: 'Pathway to advanced math courses'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0013',
          format: 'In-person/Online'
        }
      ]
    },
    'precalculus': {
      title: 'Precalculus',
      description: 'Preparation for calculus including trigonometry, functions, analytical geometry, and mathematical analysis.',
      typicalGrade: '11th or 12th grade',
      prerequisites: 'Algebra 2',
      nextCourses: ['AP Calculus AB', 'AP Calculus BC', 'Statistics'],
      materials: {
        'Common Textbooks': 'Precalculus with Limits, CPM Precalculus'
      },
      tracks: {
        standard: 'Advanced UC/CSU A-G course',
        accelerated: 'Recommended for STEM pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0016',
          format: 'In-person/Online'
        }
      ]
    },
    'geometry-h': {
      title: 'Geometry Honors',
      description: 'Advanced geometry course with deeper exploration of proofs, transformations, and geometric relationships.',
      typicalGrade: '9th grade',
      prerequisites: 'Algebra 1 with A grade',
      nextCourses: ['Algebra 2/Trig H'],
      materials: {
        'Common Textbooks': 'Big Ideas Math Geometry Advanced, CPM Honors Geometry'
      },
      tracks: {
        accelerated: 'First honors course in accelerated math pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0031H',
          format: 'In-person'
        }
      ]
    },
    'algebra-2-trig-h': {
      title: 'Algebra 2/Trigonometry Honors',
      description: 'Accelerated course combining Algebra 2 and Trigonometry with advanced problem-solving.',
      typicalGrade: '10th grade',
      prerequisites: 'Geometry H with B+ or higher',
      nextCourses: ['Analysis H'],
      materials: {
        'Common Textbooks': 'Big Ideas Math Algebra 2 Advanced, CPM Honors Algebra 2'
      },
      tracks: {
        accelerated: 'Second honors course in accelerated math pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0013H',
          format: 'In-person'
        }
      ]
    },
    'analysis-h': {
      title: 'Analysis Honors',
      description: 'Advanced pre-calculus course covering functions, limits, vectors, and introductory calculus concepts.',
      typicalGrade: '11th grade',
      prerequisites: 'Algebra 2/Trig H with B+ or higher',
      nextCourses: ['AP Calculus BC'],
      materials: {
        'Common Textbooks': 'Precalculus with Limits Advanced Placement'
      },
      tracks: {
        accelerated: 'Third honors course in accelerated math pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0016H',
          format: 'In-person'
        }
      ]
    },
    'ap-calculus-bc': {
      title: 'AP Calculus BC',
      description: 'College-level calculus course covering limits, derivatives, integrals, and series. Prepares students for the AP Calculus BC exam.',
      typicalGrade: '12th grade',
      prerequisites: 'Analysis H with B+ or higher',
      nextCourses: ['College Mathematics'],
      materials: {
        'Common Textbooks': 'Calculus: Graphical, Numerical, Algebraic AP Edition'
      },
      tracks: {
        accelerated: 'Culminating course in accelerated math pathway'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0017',
          format: 'In-person'
        }
      ]
    },
    'statistics': {
      title: 'Statistics',
      description: 'Introduction to statistical concepts including data analysis, probability, distributions, and statistical inference.',
      typicalGrade: '11th or 12th grade',
      prerequisites: 'Algebra 2',
      nextCourses: ['College Mathematics'],
      materials: {
        'Common Textbooks': 'The Practice of Statistics'
      },
      tracks: {
        standard: 'Alternative to Precalculus',
        accelerated: 'Can be taken concurrently with other math courses'
      },
      providers: [
        {
          name: 'Sierra College',
          courseCode: 'MATH 0013',
          format: 'In-person/Online'
        }
      ]
    }
  };

  const course = courseDetails[courseName] || null;

  if (!course) {
    return <div>Course not found</div>;
  }

  const handleFindOpportunities = () => {
    navigate('/coursesearch', { 
      state: { 
        subject: 'Math',
        searchQuery: course.title
      }
    });
  };

  return (
    <div style={styles.container}>
      <PageHeader title={course.title} />
      
      <div style={styles.courseInfo}>
        <div style={styles.contentGrid}>
          {/* Left Column */}
          <div style={styles.mainContent}>
            {/* Overview Section */}
            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>Course Overview</h2>
              <p style={styles.description}>{course.description}</p>
              
              <div style={styles.infoGrid}>
                <div style={styles.infoItem}>
                  <span style={styles.infoLabel}>Grade Level</span>
                  <span style={styles.infoValue}>{course.typicalGrade}</span>
                </div>
                <div style={styles.infoItem}>
                  <span style={styles.infoLabel}>Prerequisites</span>
                  <span style={styles.infoValue}>{course.prerequisites}</span>
                </div>
                <div style={styles.infoItem}>
                  <span style={styles.infoLabel}>Next Courses</span>
                  <span style={styles.infoValue}>{course.nextCourses.join(', ')}</span>
                </div>
              </div>
            </div>

            {/* Materials Section */}
            <div style={styles.section}>
              <h2 style={styles.sectionTitle}>Course Materials</h2>
              {Object.entries(course.materials).map(([category, items]) => (
                <div key={category} style={styles.materialItem}>
                  <span style={styles.materialLabel}>{category}:</span>
                  <span style={styles.materialValue}>{items}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Right Column */}
          <div style={styles.sidebar}>
            <div style={styles.trackInfo}>
              <h3 style={styles.sidebarTitle}>Track Information</h3>
              {Object.entries(course.tracks).map(([track, info]) => (
                <div key={track} style={styles.trackItem}>
                  <span style={styles.trackName}>{track}</span>
                  <span style={styles.trackDescription}>{info}</span>
                </div>
              ))}
            </div>

            <div style={styles.actionButtons}>
              <button onClick={handleFindOpportunities} style={styles.findButton}>
                Find Course Opportunities
              </button>
              <button onClick={() => navigate('/course-planning')} style={styles.planButton}>
                Back to Course Planning
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '2rem',
  },
  courseInfo: {
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    overflow: 'hidden',
  },
  contentGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: '2rem',
    padding: '2rem',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    color: '#00356b',
    fontWeight: '600',
    marginBottom: '0.5rem',
  },
  description: {
    fontSize: '1rem',
    lineHeight: '1.6',
    color: '#4a5568',
  },
  infoGrid: {
    display: 'grid',
    gap: '1rem',
    backgroundColor: '#f8f9fa',
    padding: '1.5rem',
    borderRadius: '8px',
    marginTop: '1rem',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  infoLabel: {
    fontWeight: '600',
    color: '#2d3748',
    fontSize: '0.875rem',
  },
  infoValue: {
    color: '#4a5568',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  trackInfo: {
    backgroundColor: '#f8f9fa',
    padding: '1.5rem',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  sidebarTitle: {
    fontSize: '1.25rem',
    color: '#00356b',
    fontWeight: '600',
    marginBottom: '0.5rem',
  },
  trackItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    padding: '0.75rem',
    backgroundColor: 'white',
    borderRadius: '6px',
    border: '1px solid #e2e8f0',
  },
  trackName: {
    fontWeight: '600',
    color: '#2d3748',
    textTransform: 'capitalize',
  },
  trackDescription: {
    color: '#4a5568',
    fontSize: '0.875rem',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  findButton: {
    backgroundColor: '#00356b',
    color: 'white',
    padding: '1rem',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#002548',
    },
  },
  planButton: {
    backgroundColor: '#718096',
    color: 'white',
    padding: '1rem',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#4a5568',
    },
  },
};

export default MathCourseDetail; 
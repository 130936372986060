import React from 'react';

const CollegePrep = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>College Preparation</h1>
      <p style={styles.comingSoon}>Coming Soon!</p>
      <p style={styles.message}>
        We are working on a comprehensive guide to help you navigate college applications, SAT/ACT prep, and finding the right fit for your future academic journey. Stay tuned for advice on writing personal statements, understanding financial aid options, and building a strong college resume.
      </p>
      <br></br>
      <p style={styles.message}>
        In the meantime, check out these useful resources:
        <ul>
          <li><a href="https://www.collegeboard.org" target="_blank" rel="noopener noreferrer" style={styles.link}>College Board</a> - Official SAT registration and college search tool.</li>
          <li><a href="https://www.commonapp.org" target="_blank" rel="noopener noreferrer" style={styles.link}>Common Application</a> - Simplify your college applications by applying to multiple schools with one form.</li>
          <li><a href="https://www.khanacademy.org" target="_blank" rel="noopener noreferrer" style={styles.link}>Khan Academy SAT Prep</a> - Free personalized SAT practice.</li>
        </ul>
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    padding: '24px',
    position: 'relative',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#00356b',
    marginBottom: '20px',
  },
  comingSoon: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: '10px',
  },
  message: {
    fontSize: '18px',
    color: '#555',
    textAlign: 'left',
    maxWidth: '800px',
    margin: '0 auto',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
  },
};

export default CollegePrep;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import logo from '../assets/logo.png';
import { 
  AiOutlineHome,
  AiOutlineFileText,
  AiOutlineSearch,
  AiOutlineSchedule,
  AiOutlineShop,
  AiOutlineSetting
} from 'react-icons/ai';
import { 
  BsCalendar,
  BsBook,
  BsBuilding,
  BsCash,
  BsListCheck,
  BsTrophy,
  BsBriefcase,
  BsMortarboard,
  BsCompass,
  BsShieldCheck,
  BsChatDots
} from 'react-icons/bs';

const Navbar = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const userType = localStorage.getItem('userType');

  const isActive = (path) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <nav style={styles.navbar}>
      <div style={styles.navContent}>
        <div style={styles.topContent}>
          <Link to="/" style={styles.logoLink}>
            <img src={logo} alt="Logo" style={styles.logo} />
            <span style={styles.brandName}>YourEDU</span>
          </Link>
          
          <ul style={styles.navList}>
            {user ? (
              userType === 'highschool' ? (
                <>
                  <li style={styles.navItem}>
                    <Link to="/" style={{ ...styles.navLink, ...(isActive('/') ? styles.activeNavLink : {}) }}>
                      <AiOutlineHome style={styles.icon} /> Home
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/mycourses" style={{ ...styles.navLink, ...(isActive('/mycourses') ? styles.activeNavLink : {}) }}>
                      <AiOutlineFileText style={styles.icon} /> My Courses
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/course-planning" style={{ ...styles.navLink, ...(isActive('/course-planning') ? styles.activeNavLink : {}) }}>
                      <BsListCheck style={styles.icon} /> Course Planning
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/coursesearch" style={{ ...styles.navLink, ...(isActive('/coursesearch') ? styles.activeNavLink : {}) }}>
                      <AiOutlineSearch style={styles.icon} /> Course Search
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/registration" style={{ ...styles.navLink, ...(isActive('/registration') ? styles.activeNavLink : {}) }}>
                      <AiOutlineSchedule style={styles.icon} /> Registration
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/marketplace" style={{ ...styles.navLink, ...(isActive('/marketplace') ? styles.activeNavLink : {}) }}>
                      <AiOutlineShop style={styles.icon} /> Marketplace
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/extracurriculars" style={{ ...styles.navLink, ...(isActive('/extracurriculars') ? styles.activeNavLink : {}) }}>
                      <BsTrophy style={styles.icon} /> Extracurriculars
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/internships" style={{ ...styles.navLink, ...(isActive('/internships') ? styles.activeNavLink : {}) }}>
                      <BsBriefcase style={styles.icon} /> Internships
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/colleges" style={{ ...styles.navLink, ...(isActive('/colleges') ? styles.activeNavLink : {}) }}>
                      <BsMortarboard style={styles.icon} /> College Prep
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/career-exploration" style={{ ...styles.navLink, ...(isActive('/career-exploration') ? styles.activeNavLink : {}) }}>
                      <BsCompass style={styles.icon} /> Career Exploration
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/compliance" style={{ ...styles.navLink, ...(isActive('/compliance') ? styles.activeNavLink : {}) }}>
                      <BsShieldCheck style={styles.icon} /> Compliance
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/community" style={{ ...styles.navLink, ...(isActive('/community') ? styles.activeNavLink : {}) }}>
                      <BsChatDots style={styles.icon} /> Community
                    </Link>
                  </li>
                </>
              ) : userType === 'college' ? (
                <>
                  <li style={styles.navItem}>
                    <Link to="/" style={{ ...styles.navLink, ...(isActive('/') ? styles.activeNavLink : {}) }}>
                      <AiOutlineHome style={styles.icon} /> Home
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/admin-materials" style={{ ...styles.navLink, ...(isActive('/admin-materials') ? styles.activeNavLink : {}) }}>
                      <AiOutlineFileText style={styles.icon} /> Admin Materials
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/school-search" style={{ ...styles.navLink, ...(isActive('/school-search') ? styles.activeNavLink : {}) }}>
                      <BsBuilding style={styles.icon} /> Colleges
                    </Link>
                  </li>
                  <li style={styles.navItem}>
                    <Link to="/scholarships" style={{ ...styles.navLink, ...(isActive('/scholarships') ? styles.activeNavLink : {}) }}>
                      <BsCash style={styles.icon} /> Scholarships
                    </Link>
                  </li>
                </>
              ) : null
            ) : (
              <>
                <li style={styles.navItem}>
                  <Link to="/login" style={{ ...styles.navLink, ...(isActive('/login') ? styles.activeNavLink : {}) }}>Login</Link>
                </li>
                <li style={styles.navItem}>
                  <Link to="/register" style={{ ...styles.navLink, ...(isActive('/register') ? styles.activeNavLink : {}) }}>Create Account</Link>
                </li>
              </>
            )}
          </ul>
        </div>
        
        <div style={styles.bottomContent}>
          <Link to="/account" style={styles.accountButton}>
            <div style={styles.accountButtonContent}>
              <div style={styles.emailContainer}>
                <AiOutlineSetting style={styles.settingIcon} />
                <span style={styles.emailText}>{user?.email}</span>
              </div>
            </div>
          </Link>
          <button onClick={logout} style={styles.logoutButton}>Log Out</button>
        </div>
      </div>
    </nav>
  );
};

const styles = {
  navbar: {
    backgroundColor: '#ffffff',
    width: '250px',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    borderRight: '1px solid #3182CE',
    boxShadow: '2px 0 4px rgba(49, 130, 206, 0.1)',
    fontFamily: "'Inter', sans-serif",
    display: 'flex',
    flexDirection: 'column',
  },
  navContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px 0',
  },
  topContent: {
    flex: 1,
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: '0 20px',
    marginBottom: '30px',
  },
  logo: {
    height: '36px',
    marginRight: '8px',
  },
  brandName: {
    fontSize: '18px',
    color: '#2B6CB0',
    fontWeight: '600',
    fontFamily: "'Inter', sans-serif",
    letterSpacing: '-0.3px',
  },
  navList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  navItem: {
    margin: '4px 0',
  },
  navLink: {
    color: '#2B6CB0',
    textDecoration: 'none',
    padding: '10px 20px',
    fontSize: '14px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#EBF8FF',
    },
  },
  activeNavLink: {
    backgroundColor: '#3182CE',
    color: '#ffffff',
    fontWeight: '600',
  },
  bottomContent: {
    padding: '20px',
    borderTop: '1px solid #E2E8F0',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  accountButton: {
    color: '#1a365d',
    textDecoration: 'none',
    fontSize: '13px',
    fontWeight: '500',
    padding: '8px',
    borderRadius: '6px',
    backgroundColor: '#f7fafc',
    '&:hover': {
      backgroundColor: '#edf2f7',
    },
  },
  accountButtonContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: '100%',
  },
  settingIcon: {
    fontSize: '16px',
    color: '#4A5568',
  },
  logoutButton: {
    backgroundColor: '#1a365d',
    color: '#ffffff',
    border: 'none',
    borderRadius: '6px',
    padding: '8px',
    fontSize: '13px',
    fontWeight: '500',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: '#2c5282',
    },
  },
  icon: {
    fontSize: '18px',
    minWidth: '18px',
  },
  emailContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  emailText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
};

export default Navbar;

import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import { useNavigate } from 'react-router-dom';

const Marketplace = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  
  // Mock data for education providers
  const providers = [
    {
      id: 1,
      name: "Math-U-See",
      logo: "https://placeholder.com/150", // Replace with actual logo
      description: "Complete math curriculum with manipulatives and video instruction",
      discount: "15% off all curriculum packages",
      category: "Curriculum"
    },
    {
      id: 2,
      name: "Discovery Education",
      logo: "https://placeholder.com/150",
      description: "Digital learning platform with interactive science resources",
      discount: "20% off annual subscriptions",
      category: "Online Learning"
    },
    // Add more mock providers
  ];

  const filteredProviders = providers.filter(provider =>
    provider.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    provider.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
    provider.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <div style={styles.titleSection}>
          <PageHeader title="Education Marketplace" />
        </div>
        <div style={styles.ctaSection}>
          <span style={styles.ctaText}>Want to get listed here?</span>
          <button 
            onClick={() => window.location.href = 'mailto:partners@youredu.com'}
            style={styles.ctaButton}
          >
            Partner With Us
          </button>
        </div>
      </div>

      {/* Search Bar */}
      <div style={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search providers..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={styles.searchBar}
        />
      </div>

      {/* Provider Grid */}
      <div style={styles.providerGrid}>
        {filteredProviders.map(provider => (
          <div key={provider.id} style={styles.providerCard}>
            <img src={provider.logo} alt={provider.name} style={styles.logo} />
            <h3 style={styles.providerName}>{provider.name}</h3>
            <p style={styles.description}>{provider.description}</p>
            <div style={styles.discountBadge}>
              {provider.discount}
            </div>
            <button 
              onClick={() => navigate(`/marketplace/${provider.id}`)}
              style={styles.learnMoreButton}
            >
              Learn More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '24px',
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '32px',
  },
  titleSection: {
    flex: '1',
  },
  ctaSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  ctaText: {
    fontSize: '16px',
    color: '#666',
    whiteSpace: 'nowrap',
  },
  ctaButton: {
    padding: '8px 16px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  },
  searchContainer: {
    marginBottom: '32px',
  },
  searchBar: {
    width: '100%',
    padding: '12px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  },
  providerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '24px',
  },
  providerCard: {
    padding: '24px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    border: '1px solid #eee',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  logo: {
    width: '150px',
    height: '150px',
    objectFit: 'contain',
  },
  providerName: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '0',
  },
  description: {
    fontSize: '14px',
    color: '#666',
    textAlign: 'center',
    margin: '0',
  },
  discountBadge: {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '8px 16px',
    borderRadius: '20px',
    fontSize: '14px',
    fontWeight: '500',
  },
  learnMoreButton: {
    padding: '8px 24px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
};

export default Marketplace; 
import React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../components/PageHeader';

const MarketplaceDetail = () => {
  const { providerId } = useParams();

  // Mock provider data - replace with actual data fetching
  const providerDetails = {
    1: {
      name: "Math-U-See",
      logo: "https://placeholder.com/300",
      description: "Complete math curriculum with manipulatives and video instruction",
      longDescription: "Math-U-See is a complete K-12 math curriculum focused on mastery learning through hands-on manipulatives and systematic instruction. The program uses multi-sensory teaching to help students understand, master, and love math.",
      discount: "15% off all curriculum packages",
      website: "https://mathusee.com",
      features: [
        "Video instruction for every lesson",
        "Manipulative-based learning",
        "Mastery approach",
        "Teacher guides included",
        "Online support resources"
      ],
      testimonials: [
        {
          text: "Math-U-See transformed how my children learn mathematics",
          author: "Sarah M., Homeschool Parent"
        }
      ]
    }
    // Add more provider details as needed
  };

  const provider = providerDetails[providerId];

  if (!provider) {
    return <div>Provider not found</div>;
  }

  return (
    <div style={styles.container}>
      <PageHeader title={provider.name} />
      
      <div style={styles.content}>
        <div style={styles.header}>
          <img src={provider.logo} alt={provider.name} style={styles.logo} />
          <div style={styles.discountBadge}>{provider.discount}</div>
        </div>

        <div style={styles.description}>
          <p style={styles.longDescription}>{provider.longDescription}</p>
        </div>

        <div style={styles.features}>
          <h3 style={styles.sectionTitle}>Features</h3>
          <ul style={styles.featureList}>
            {provider.features.map((feature, index) => (
              <li key={index} style={styles.featureItem}>{feature}</li>
            ))}
          </ul>
        </div>

        <div style={styles.testimonials}>
          <h3 style={styles.sectionTitle}>What Users Say</h3>
          {provider.testimonials.map((testimonial, index) => (
            <div key={index} style={styles.testimonial}>
              <p style={styles.testimonialText}>"{testimonial.text}"</p>
              <p style={styles.testimonialAuthor}>- {testimonial.author}</p>
            </div>
          ))}
        </div>

        <div style={styles.cta}>
          <a 
            href={provider.website} 
            target="_blank" 
            rel="noopener noreferrer"
            style={styles.ctaButton}
          >
            Visit Website
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '24px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  content: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '32px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '32px',
  },
  logo: {
    width: '300px',
    height: 'auto',
    objectFit: 'contain',
  },
  discountBadge: {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '12px 24px',
    borderRadius: '20px',
    fontSize: '18px',
    fontWeight: '500',
  },
  description: {
    marginBottom: '32px',
  },
  longDescription: {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#444',
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '16px',
    color: '#333',
  },
  features: {
    marginBottom: '32px',
  },
  featureList: {
    listStyle: 'none',
    padding: 0,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '16px',
  },
  featureItem: {
    padding: '12px',
    backgroundColor: '#f8f9fa',
    borderRadius: '4px',
    fontSize: '14px',
  },
  testimonials: {
    marginBottom: '32px',
  },
  testimonial: {
    padding: '24px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  testimonialText: {
    fontSize: '16px',
    fontStyle: 'italic',
    marginBottom: '8px',
  },
  testimonialAuthor: {
    fontSize: '14px',
    color: '#666',
  },
  cta: {
    textAlign: 'center',
    marginTop: '32px',
  },
  ctaButton: {
    display: 'inline-block',
    padding: '16px 32px',
    backgroundColor: '#007bff',
    color: 'white',
    textDecoration: 'none',
    borderRadius: '4px',
    fontSize: '18px',
    fontWeight: '500',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
};

export default MarketplaceDetail; 
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import yourEduLogo from '../assets/logo.png';

const Internships = () => {
  const navigate = useNavigate();
  const [savedInternships, setSavedInternships] = useState([]);

  const internships = [
    {
      id: 1,
      company: "YawAI",
      logo: yourEduLogo,
      sector: "Artificial Intelligence",
      description: "Join our AI team to help develop cutting-edge language models and AI applications. Work directly with senior engineers on real-world AI projects.",
      role: "AI Research Apprentice",
      duration: "6 months",
    },
    {
      id: 2,
      company: "YourEDU",
      logo: yourEduLogo,
      sector: "Education Technology",
      description: "Help shape the future of education by working on our platform. Learn full-stack development and educational technology implementation.",
      role: "EdTech Developer Intern",
      duration: "6 months",
    },
    {
      id: 3,
      company: "TechStart",
      logo: yourEduLogo,
      sector: "Technology",
      description: "Join our dynamic tech team and learn software development hands-on with modern web technologies.",
      role: "Junior Developer Apprentice",
      duration: "3 months",
    },
    {
      id: 4,
      company: "GreenEarth",
      logo: yourEduLogo,
      sector: "Environmental",
      description: "Help develop sustainable solutions while learning project management and environmental impact analysis.",
      role: "Sustainability Coordinator",
      duration: "3 months",
    },
    {
      id: 5,
      company: "FinTech Solutions",
      logo: yourEduLogo,
      sector: "Finance",
      description: "Learn financial technology and data analysis in a fast-paced environment while working with blockchain technology.",
      role: "Financial Analysis Intern",
      duration: "1 year",
    },
  ];

  const handleSave = (internship) => {
    if (!savedInternships.find(saved => saved.id === internship.id)) {
      setSavedInternships([...savedInternships, internship]);
    }
  };

  const handleRemove = (internshipId) => {
    setSavedInternships(savedInternships.filter(internship => internship.id !== internshipId));
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <PageHeader title="YourEDU Internships" />
        <button 
          onClick={() => navigate('/internships/apply')} 
          style={styles.applyButton}
        >
          Apply Now
        </button>
      </div>
      
      <div style={styles.description}>
        <p>
          Welcome to YourEDU's Internship Program! We offer unique apprenticeship opportunities 
          with leading companies where homeschoolers and high school students can gain valuable 
          experience, receive mentorship, and earn high school credit. Our positions span from 
          technical to business roles, providing diverse learning experiences.
        </p>
      </div>

      {savedInternships.length > 0 && (
        <div style={styles.savedSection}>
          <h2 style={styles.sectionTitle}>Saved for Later</h2>
          <div style={styles.savedGrid}>
            {savedInternships.map(internship => (
              <div key={internship.id} style={styles.savedCard}>
                <img src={internship.logo} alt={internship.company} style={styles.smallLogo} />
                <div style={styles.savedInfo}>
                  <h3>{internship.company}</h3>
                  <p>{internship.role}</p>
                </div>
                <button 
                  onClick={() => handleRemove(internship.id)}
                  style={styles.removeButton}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div style={styles.internshipGrid}>
        {internships.map(internship => (
          <div key={internship.id} style={styles.internshipCard}>
            <div style={styles.cardHeader}>
              <img src={internship.logo} alt={internship.company} style={styles.logo} />
              <span style={styles.sector}>{internship.sector}</span>
            </div>
            <h2 style={styles.companyName}>{internship.company}</h2>
            <h3 style={styles.role}>{internship.role}</h3>
            <p style={styles.description}>{internship.description}</p>
            <div style={styles.cardFooter}>
              <span style={styles.duration}>Duration: {internship.duration}</span>
              <button 
                onClick={() => handleSave(internship)}
                style={styles.saveButton}
                disabled={savedInternships.some(saved => saved.id === internship.id)}
              >
                {savedInternships.some(saved => saved.id === internship.id) ? 'Saved' : 'Save'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f8f9fa',
    minHeight: '100vh',
    padding: '24px',
    position: 'relative',
  },
  description: {
    backgroundColor: '#f0f7ff',
    padding: '1.5rem',
    borderRadius: '10px',
    marginBottom: '2rem',
    fontSize: '1.1rem',
    lineHeight: '1.6',
    color: '#2c5282',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  applyButton: {
    backgroundColor: '#4299e1',
    color: 'white',
    padding: '0.8rem 1.5rem',
    borderRadius: '8px',
    border: 'none',
    fontSize: '1.1rem',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#3182ce',
    },
  },
  internshipGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '2rem',
    marginTop: '2rem',
  },
  internshipCard: {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '1.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  savedSection: {
    marginBottom: '2rem',
    backgroundColor: '#f7fafc',
    padding: '1.5rem',
    borderRadius: '10px',
  },
  savedGrid: {
    display: 'flex',
    gap: '1rem',
    overflowX: 'auto',
    padding: '1rem 0',
  },
  savedCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    minWidth: '300px',
  },
  saveButton: {
    backgroundColor: '#3182ce',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#2c5282',
    },
    '&:disabled': {
      backgroundColor: '#90cdf4',
      cursor: 'not-allowed',
    },
  },
  removeButton: {
    backgroundColor: '#fc8181',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    marginLeft: 'auto',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#f56565',
    },
  },
  logo: {
    width: '50px',
    height: '50px',
    objectFit: 'contain',
  },
  smallLogo: {
    width: '40px',
    height: '40px',
    objectFit: 'contain',
  },
};

export default Internships; 
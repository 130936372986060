import { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import WeeklySchedule from '../components/WeeklySchedule';
import { FiTrash2 } from 'react-icons/fi';

// The main Course Planning page
const CoursePlanning = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('planner'); // Change default tab
  const [currentTerm, setCurrentTerm] = useState('Winter 2024-2025');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [activeSubject, setActiveSubject] = useState(null);
  const [showMainGrid, setShowMainGrid] = useState(true);
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState({
    math: { '9th': [], '10th': [], '11th': [], '12th': [] },
    science: { '9th': [], '10th': [], '11th': [], '12th': [] },
    english: { '9th': [], '10th': [], '11th': [], '12th': [] },
    history: { '9th': [], '10th': [], '11th': [], '12th': [] },
    language: { '9th': [], '10th': [], '11th': [], '12th': [] },
    fineArts: { '9th': [], '10th': [], '11th': [], '12th': [] },
    pe: { '9th': [], '10th': [], '11th': [], '12th': [] },
  });

  const grades = ['9th', '10th', '11th', '12th'];
  const subjects = [
    { name: 'Math', key: 'math' },
    { name: 'Science', key: 'science' },
    { name: 'English', key: 'english' },
    { name: 'History', key: 'history' },
    { name: 'Language', key: 'language' },
    { name: 'Fine Arts', key: 'fineArts' },
    { name: 'PE', key: 'pe' }
  ];

  const [subjectCourses, setSubjectCourses] = useState({
    math: [],
    science: [],
    english: [],
    history: [],
    language: [],
    fineArts: [],
    pe: []
  });

  const [showTracks, setShowTracks] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);

  // Load saved course data when component mounts
  useEffect(() => {
    const loadPlanningData = () => {
      const savedData = JSON.parse(localStorage.getItem('coursePlanningData') || '{}');
      console.log('Loading saved planning data:', savedData);
      
      // Initialize empty structure if no data exists
      const initializedData = {
        math: { '9th': [], '10th': [], '11th': [], '12th': [] },
        science: { '9th': [], '10th': [], '11th': [], '12th': [] },
        english: { '9th': [], '10th': [], '11th': [], '12th': [] },
        history: { '9th': [], '10th': [], '11th': [], '12th': [] },
        language: { '9th': [], '10th': [], '11th': [], '12th': [] },
        fineArts: { '9th': [], '10th': [], '11th': [], '12th': [] },
        pe: { '9th': [], '10th': [], '11th': [], '12th': [] },
      };
      
      // Merge saved data with initialized structure
      Object.keys(initializedData).forEach(subject => {
        if (savedData[subject]) {
          Object.keys(initializedData[subject]).forEach(grade => {
            initializedData[subject][grade] = savedData[subject][grade] || [];
          });
        }
      });
      
      console.log('Initialized data:', initializedData);
      setCourseData(initializedData);
    };
    
    loadPlanningData();
  }, []);

  // First, let's fix the grid layout and rendering
  const renderGrid = () => {
    return (
      <div style={styles.gridContainer}>
        {/* Grade Headers */}
        <div style={styles.headerRow}>
          <div style={styles.emptyCell}></div>
          {grades.map(grade => (
            <div key={grade} style={styles.gradeHeader}>
              {grade} Grade
            </div>
          ))}
        </div>

        {/* Subject Rows */}
        {subjects.map(subject => (
          <div key={subject.key} style={styles.row}>
            <div style={styles.subjectCell}>
              <span>{subject.name}</span>
              <button 
                style={styles.viewTracksButton}
                onClick={() => handleViewTracks(subject)}
              >
                View Tracks
              </button>
            </div>
            {grades.map(grade => (
              <div key={`${subject.key}-${grade}`} style={styles.courseCell}>
                {renderCourseData(subject.key, grade)}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  // Update the debug view to only show non-empty courses
  const renderSavedCoursesDebug = () => {
    const nonEmptyCourses = Object.entries(courseData).flatMap(([subject, grades]) =>
      Object.entries(grades).flatMap(([grade, courses]) =>
        courses.map(course => ({
          subject,
          grade,
          ...course
        }))
      )
    ).filter(course => course);

    if (nonEmptyCourses.length === 0) {
      return (
        <div style={styles.debugContainer}>
          <h3 style={styles.debugTitle}>Saved Courses (Debug View):</h3>
          <div style={styles.debugEmpty}>No courses saved yet</div>
        </div>
      );
    }

    return (
      <div style={styles.debugContainer}>
        <h3 style={styles.debugTitle}>Saved Courses (Debug View):</h3>
        {nonEmptyCourses.map((course, idx) => (
          <div key={idx} style={styles.debugItem}>
            <strong>{course.subject} - {course.grade}:</strong>
            <div style={styles.debugCourse}>
              • {course.courseTitle} ({course.days?.join(', ')} {course.startTime}-{course.endTime})
            </div>
          </div>
        ))}
      </div>
    );
  };

  // Update the renderCourseData function
  const renderCourseData = (subjectKey, grade) => {
    const courses = courseData[subjectKey]?.[grade] || [];
    console.log(`Rendering ${subjectKey} ${grade}:`, courses);
    
    return (
      <div style={styles.courseCell}>
        {courses.map((course, index) => (
          <div key={`${course.id}-${index}`} style={styles.coursePill}>
            <div style={styles.courseInfo}>
              <div style={styles.courseTitle}>{course.courseTitle}</div>
              <div style={styles.courseDetails}>
                {course.days.join(', ')} {course.startTime}-{course.endTime}
              </div>
            </div>
            <button 
              onClick={() => {
                const updatedData = {...courseData};
                updatedData[subjectKey][grade] = courses.filter((_, i) => i !== index);
                setCourseData(updatedData);
                localStorage.setItem('coursePlanningData', JSON.stringify(updatedData));
              }}
              style={styles.removeButton}
            >
              <FiTrash2 />
            </button>
          </div>
        ))}
      </div>
    );
  };

  const handleSubjectClick = (subjectKey) => {
    setActiveSubject(subjectKey);
    setShowMainGrid(false);
  };

  const handleBackClick = () => {
    setActiveSubject(null);
    setShowMainGrid(true);
  };

  // Add this useEffect to save course data when it changes
  useEffect(() => {
    localStorage.setItem('coursePlanningData', JSON.stringify(courseData));
  }, [courseData]);

  // Add this useEffect to debug courseData changes
  useEffect(() => {
    console.log('Course data updated:', courseData);
  }, [courseData]);

  const handleViewTracks = (subject) => {
    setSelectedSubject(subject);
    setShowTracks(true);
  };

  const handleBackToGrid = () => {
    setShowTracks(false);
    setSelectedSubject(null);
  };

  // Add this function to handle course clicks
  const handleCourseClick = (course) => {
    console.log('Navigating to:', `/math/${course.id}`);
    navigate(`/math/${course.id}`);
  };

  // Update the renderTracks function
  const renderTracks = () => {
    if (!selectedSubject) return null;

    const tracks = getTracksForSubject(selectedSubject.key);

    return (
      <div style={styles.tracksContainer}>
        <button style={styles.backButton} onClick={handleBackToGrid}>
          ← Back to Grid
        </button>
        <h2 style={styles.trackHeader}>{selectedSubject.name} Tracks</h2>
        <div style={styles.trackContainer}>
          {tracks.map((track, index) => (
            <div key={index} style={styles.trackSection}>
              <h3 style={styles.trackTitle}>{track.name}</h3>
              <div style={styles.courseList}>
                {track.courses.map((course, courseIndex) => (
                  <div 
                    key={courseIndex} 
                    style={styles.courseBox}
                    onClick={() => handleCourseClick(course)}
                  >
                    {course.name}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getTracksForSubject = (subjectKey) => {
    const tracksBySubject = {
      math: [
        {
          name: 'Standard Track',
          courses: [
            { id: 'algebra-1', name: 'Algebra 1' },
            { id: 'geometry', name: 'Geometry' },
            { id: 'algebra-2', name: 'Algebra 2' },
            { id: 'ap-calculus-ab', name: 'AP Calculus AB' }
          ]
        },
        {
          name: 'Advanced Track',
          courses: [
            { id: 'geometry-h', name: 'Geometry' },
            { id: 'algebra-2-trig-h', name: 'Algebra 2' },
            { id: 'analysis-h', name: 'Analysis/Pre-Calculus' },
            { id: 'ap-calculus-bc', name: 'AP Calculus BC' }
          ]
        },
        {
          name: 'Electives',
          courses: [
            { id: 'statistics', name: 'Statistics' },
            { id: 'multivariable-calculus', name: 'Multivariable Calculus' },
            { id: 'differential-equations', name: 'Differential Equations' },
            { id: 'discrete-math', name: 'Discrete Math' }
          ]
        }
      ]
    };
    return tracksBySubject[subjectKey] || [];
  };

  return (
    <div style={styles.container}>
      <PageHeader title="Course Planning" />
      
      <div style={styles.tabContainer}>
        <button 
          style={{
            ...styles.tabButton,
            ...(activeTab === 'planner' ? styles.activeTab : {})
          }}
          onClick={() => setActiveTab('planner')}
        >
          Planner
        </button>
        <button 
          style={{
            ...styles.tabButton,
            ...(activeTab === 'schedule' ? styles.activeTab : {})
          }}
          onClick={() => setActiveTab('schedule')}
        >
          Schedule
        </button>
      </div>

      {activeTab === 'planner' && (
        <>
          {showTracks ? renderTracks() : (
            <>
              {renderSavedCoursesDebug()}
              {renderGrid()}
            </>
          )}
        </>
      )}
      
      {activeTab === 'schedule' && (
        <div style={styles.scheduleContainer}>
          <WeeklySchedule />
        </div>
      )}
    </div>
  );
};

// Styles
const styles = {
  container: {
    padding: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    fontSize: '2rem',
    color: '#333',
  },
  createButton: {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  courseCard: {
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '2rem',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    marginTop: '1rem',
  },
  gridContainer: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    overflowX: 'auto',
    width: '100%',
  },
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '200px repeat(4, 1fr)',
    gap: '16px',
    marginBottom: '16px',
    position: 'sticky',
    top: 0,
    backgroundColor: '#ffffff',
    zIndex: 1,
  },
  emptyCell: {
    width: '200px',
  },
  gradeHeader: {
    padding: '12px',
    backgroundColor: '#f8fafc',
    borderRadius: '6px',
    fontWeight: '600',
    color: '#2d3748',
    textAlign: 'center',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '200px repeat(4, 1fr)',
    gap: '16px',
    marginBottom: '16px',
  },
  subjectCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '12px',
    backgroundColor: '#f8fafc',
    borderRadius: '6px',
    fontWeight: '500',
  },
  viewTracksButton: {
    padding: '4px 8px',
    fontSize: '12px',
    backgroundColor: '#e2e8f0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#4a5568',
    '&:hover': {
      backgroundColor: '#cbd5e0',
    },
  },
  courseCell: {
    padding: '1rem',
    backgroundColor: '#f8fafc',
    borderRadius: '6px',
    border: '1px solid #e2e8f0',
    minHeight: '100px',
  },
  debugContainer: {
    backgroundColor: '#f8f9fa',
    padding: '1rem',
    marginBottom: '1.5rem',
    borderRadius: '8px',
    border: '1px solid #e2e8f0',
  },
  debugTitle: {
    color: '#2d3748',
    marginBottom: '1rem',
    fontSize: '1.1rem',
  },
  debugEmpty: {
    color: '#718096',
    fontStyle: 'italic',
  },
  debugItem: {
    marginBottom: '0.5rem',
    padding: '0.5rem',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  debugCourse: {
    marginLeft: '1rem',
    color: '#4a5568',
    fontSize: '0.9rem',
  },
  courseBox: {
    backgroundColor: '#f8f9fa',
    padding: '1rem',
    borderRadius: '8px',
    border: '1px solid #dee2e6',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      backgroundColor: '#fff',
    }
  },
  courseTile: {
    backgroundColor: '#ffffff',
    border: '1px solid #e2e8f0',
    borderRadius: '6px',
    padding: '12px',
    marginBottom: '8px',
    cursor: 'grab',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      borderColor: '#cbd5e0',
    },
  },
  instructionBox: {
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    border: '1px solid #e2e8f0',
    padding: '1rem',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  instructionContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    justifyContent: 'flex-start',
  },
  arrowDown: {
    fontSize: '1.5rem',
    color: '#00356b',
    animation: 'bounce 1s infinite',
  },
  instructionText: {
    margin: 0,
    fontSize: '1rem',
    color: '#4a5568',
    fontWeight: '500',
  },
  '@keyframes bounce': {
    '0%, 100%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(5px)',
    },
  },
  trackHeader: {
    fontSize: '28px',
    color: '#00356b',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  trackContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1.5rem',
    marginTop: '2rem',
  },
  trackSection: {
    backgroundColor: '#ffffff',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  trackTitle: {
    fontSize: '20px',
    color: '#333',
    marginBottom: '10px'
  },
  courseList: {
    display: 'grid',
    gap: '1rem',
    marginTop: '1rem',
  },
  courseBox: {
    backgroundColor: '#f8f9fa',
    padding: '1rem',
    borderRadius: '8px',
    border: '1px solid #dee2e6',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      backgroundColor: '#fff',
    }
  },
  button: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#0056b3',
    }
  },
  planCourseButton: {
    backgroundColor: '#28a745',
    color: '#ffffff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '1rem',
  },
  applyButton: {
    backgroundColor: '#007bff',
    color: '#ffffff',
    padding: '0.75rem 1.5rem',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    borderRadius: '10px',
  },
  imageSubtitle: {
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#555',
    marginTop: '10px'
  },
  planningContainer: {
    width: '100%',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    top: '0',
    left: '0',
    padding: '8px 16px',
    backgroundColor: 'transparent',
    color: '#007BFF',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: '500',
    '&:hover': {
      color: '#0056b3',
    },
  },
  tabContainer: {
    display: 'flex',
    gap: '1px',
    backgroundColor: '#e0e0e0',
    padding: '2px',
    borderRadius: '8px',
    marginBottom: '24px',
    width: 'fit-content',
  },
  tabButton: {
    padding: '12px 24px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    color: '#666',
    borderRadius: '6px',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  activeTab: {
    backgroundColor: 'white',
    color: '#007bff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  },
  scheduleContainer: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
    minHeight: '400px',
  },
  comingSoon: {
    textAlign: 'center',
    padding: '48px',
    color: '#666',
  },
  plannerGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '24px',
    padding: '20px',
  },
  subjectBin: {
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    padding: '16px',
    minHeight: '200px',
  },
  subjectHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  subjectTitle: {
    margin: 0,
    color: '#2d3748',
    fontSize: '18px',
    fontWeight: '500',
  },
  coursesBin: {
    minHeight: '150px',
    backgroundColor: 'white',
    borderRadius: '6px',
    padding: '12px',
    border: '2px dashed #e2e8f0',
  },
  courseTile: {
    backgroundColor: '#f8f9fa',
    borderRadius: '6px',
    padding: '12px',
    marginBottom: '8px',
    border: '1px solid #e2e8f0',
  },
  courseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '4px',
  },
  courseId: {
    fontWeight: '600',
    color: '#00356b',
  },
  courseName: {
    color: '#4a5568',
    fontSize: '14px',
  },
  crossListed: {
    color: '#718096',
    fontSize: '12px',
    marginTop: '4px',
  },
  removeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#a0aec0',
    cursor: 'pointer',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#e53e3e',
    },
  },
  courseCell: {
    padding: '8px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    minHeight: '100px',
    border: '1px solid #e2e8f0',
  },
  coursePill: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: '#f8fafc',
    padding: '8px 12px',
    margin: '4px 0',
    borderRadius: '4px',
    border: '1px solid #e2e8f0',
    boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
  },
  courseInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    flex: 1,
    overflow: 'hidden',
  },
  courseTitle: {
    fontWeight: '500',
    color: '#2d3748',
    fontSize: '0.9rem',
    wordBreak: 'break-word',
  },
  courseDetails: {
    fontSize: '0.8rem',
    color: '#718096',
    wordBreak: 'break-word',
  },
  viewTracksButton: {
    backgroundColor: '#00356b',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    padding: '6px 12px',
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '8px',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#002548',
    }
  },
  tracksContainer: {
    position: 'relative',
    padding: '20px',
    paddingTop: '48px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  },
};

export default CoursePlanning;
